<template>
    <div class="profileaccountscreen">
        <div class="page-wrapper">
            <ProfileAccountComponent />
        </div>
    </div>
</template>

<script>
import ProfileAccountComponent from '@/components/users/ProfileAccountComponent.vue';
export default {
    components: {
        ProfileAccountComponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped>
/* .profileaccountscreen {
    min-height: 100vh;
    position: relative;
}

.page-wrapper {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
} */
</style>