<template>
    <div class="privacy-page-wrapper">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else class="privacy">
            <div class="containers">
                <div class="privacy_policy">
                    <span>Privacy </span><span style="color: #DE5D0A;">Policy</span>
                </div>
                <div class="row">
                    <div class="col-lg-7 col-md-6 col-sm-12">
                        <p class="text_p">Vireak Buntham fully respects your privacy and recognizes the need to protect
                            the personally identifiable information (any information by which you can be identified,
                            such as name, address, and telephone number) you share with us. We would like to assure you
                            that we follow appropriate standards when it comes to protecting your privacy on our web
                            sites</p>
                        <p class="text_p">Our privacy policy is subject to change at any time without notice. To make
                            sure you are aware of any changes, please review this policy periodically. By visiting this
                            Website you agree to be bound by the terms and conditions of this Privacy Policy. If you do
                            not agree please do not use or access our Website. By mere use of this Website, you
                            expressly consent to our use and disclosure of your personal information in accordance with
                            this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of
                            Use</p>
                        <p class="text_p">Vireak Buntham will not sell, trade or disclose to third parties any
                            information derived from the registration for, or use of, any online service (including
                            names and addresses) without the consent of the user or customer (except as required by
                            subpoena, search warrant, or other legal process or in the case of imminent physical harm to
                            the user or others)</p>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-12 text-center" style="height: 360px;">
                        <div class="privacy_img">
                            <img src="@/assets/terms/privacy.svg" alt="">
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>

<script>

export default {
    data() {
        return {
            loading: true,
            reloadPrivacy: "",
        }
    },
    methods: {

    },
    created() {
        localStorage.setItem('reloadHome', 'false');
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadBlog', 'false');


        this.reloadPrivacy = localStorage.getItem('reloadPrivacy');
        if (this.reloadPrivacy != 'true') {
            localStorage.setItem('reloadPrivacy', 'true');
        }
        if (localStorage.getItem('reloadPrivacy') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    },
}
</script>

<style>
.privacy {
    padding-top: 120px;
    padding-bottom: 50px;
    /* height: 75vh; */
    /* background-color: #F6F7F8; */
}

.privacy_policy {
    padding-bottom: 20px;
}

.privacy_policy span {
    font-size: 36px;
    font-weight: 600;
    color: #1D1D1D;
}

.text_p {
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 1.6;
}

.privacy_img {
    width: 100%;
    height: 100%;
}

.privacy_img img {
    width: 100%;
    height: 100%;
}

@media(width < 960px) {
    .privacy {
        /* padding-bottom: 550px; */
    }
}

@media(width < 650px) {
    .privacy {
        padding-top: 100px;
    }
}

.privacy-page-wrapper {
    /* min-height: 100vh; */
    position: relative;
    display: flex;
    flex-direction: column;
}

.privacy {
    flex: 1;
}

.footer-container {
    position: relative;
    width: 100%;
    bottom: 0;
}
</style>