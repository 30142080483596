<template>
    <div class="shedule">
        <div class="chooseSeat">
            <choose-seat-back />
        </div>
    </div>
</template>

<script>
import ChooseSeatBack from '@/components/seats/ChooseSeatBack.vue';
export default {
    components: {
        ChooseSeatBack,
    },
    data() {
        return {}
    },
    methods: {},
    created() { }
}
</script>

<style scoped></style>