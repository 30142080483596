<template>
  <div class="resort-listing">
    <div class="slider">
      <div class="slider-container">
        <img v-for="(image, i) in sliderImages" :key="i" :src="image" :class="{ active: currentIndex === i }"
          alt="Slider Image" />
      </div>
      <!-- <div class="slider-dots">
          <span 
            v-for="(_, i) in sliderImages" 
            :key="i"
            class="dot" 
            :class="{ active: currentIndex === i }"
            @click="setCurrentSlide(i)"
          ></span>
        </div> -->
      <!-- <button class="slider-btn prev" @click="prevSlide">&lt;</button> -->
      <!-- <button class="slider-btn next" @click="nextSlide">&gt;</button> -->
    </div>

    <div class="resort-list">
      <div v-for="resort in resorts" :key="resort.id" class="resort-card">
        <div class="resort-image">
          <img :src="resort.image" :alt="resort.name" />
        </div>

        <div class="resort-info">
          <div class="resort-header">
            <h2>{{ resort.name }}</h2>
            <div class="button-group">
              <!-- <button class="redirect-btn" >Visit</button> -->
              <button class="detail-btn" @click="handleRedirect(resort.RequiredUrl)">Book Now</button>
            </div>
          </div>

          <div class="location" v-if="hasLocation(resort)">
            <svg xmlns="http://www.w3.org/2000/svg" class="location-icon" fill="none" viewBox="0 0 24 24"
              stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z" />
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <p>{{ resort.location }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import sliceshow from "@/assets/esperanza-inlet-sunrise-1080x480.jpg"
export default {
  name: 'ResortListing',
  data() {
    return {
      currentIndex: 0,
      sliderImages: [
        require('@/assets/resort/cover website _3.jpg'),
        // require('@/assets/image.jpg'),
        // require('@/assets/0204h12000a5tqdrv8D9D_R_960_660_R5_D.jpg')
      ],
      resorts: [
        {
          id: 1,
          name: 'Pidoma Resort',
          location: '',
          image: require('@/assets/resort/pidoma.jpg'),
          RequiredUrl: 'https://www.pidomaresort.com/'
        },
        {
          id: 2,
          name: 'One Beach Resort',
          location: '',
          image: require('@/assets/resort/one-beach.jpg'),
          RequiredUrl: 'https://onebeach-resort.com/'
        },
        {
          id: 3,
          name: 'Tube Resort',
          location: '',
          image: require('@/assets/resort/tube.jpg'),
          RequiredUrl: 'https://tube-resort.com/'
        },
        {
          id: 4,
          name: 'Romdoul Kirirom Resort',
          location: '',
          image: require('@/assets/resort/romdoul-kirirom.jpg'),
          RequiredUrl: 'https://romdoulresort.com/'
        },
        {
          id: 5,
          name: 'Romhaey Kirirom Resort',
          location: '',
          image: require('@/assets/resort/Romhaey.jpg'),
          RequiredUrl: 'https://romhaeykiriromresort.com/'
        },
        {
          id: 6,
          name: 'Camping Park Resort',
          location: '',
          image: require('@/assets/resort/Campingpark.jpg'),
          RequiredUrl: 'https://campingpark-kirirom.com/'
        },
        {
          id: 7,
          name: 'Koh Sdach Resort',
          location: '',
          image: require('@/assets/resort/koh-sdach.jpg'),
          RequiredUrl: 'https://www.kohsdachislandresort.com/'
        }
      ]
    }
  },
  methods: {
    hasLocation(resort) {
      return resort.location && resort.location.trim().length > 0;
    },
    handleDetailClick(resort) {
      this.$router.push(`/resort/${resort.id}`)
    },
    handleRedirect(url) {
      window.location.href = url
    },
    nextSlide() {
      if (this.slides && this.slides.length > 0) {
        // Proceed with your code logic
        // } else {
        //   console.error("Slides array is undefined or empty.");
      }

    },
    prevSlide() {
      this.currentIndex = (this.currentIndex - 1 + this.sliderImages.length) % this.sliderImages.length
    },
    setCurrentSlide(index) {
      this.currentIndex = index
    }
  },
  mounted() {
    setInterval(this.nextSlide, 5000)
  }
}
</script>

<style scoped>
/* Main Listing Container */
.resort-listing {
  max-width: 100%;
  margin: 0 auto;
  /* background-color: #fff; */
  font-family: Arial, sans-serif;
}

/* Slider Styles */
.slider {
  position: relative;
  width: 100%;
  /* Full viewport width */
  overflow: hidden;
  padding-top: 20px;
}

.slider-container img.active {
  opacity: 1;
}

/* Resort List as Grid */
.resort-list {
  padding-left: 10px;
  padding-right: 10px;
  display: grid;
  gap: 16px;
  padding-top: 16px;
  grid-template-columns: repeat(2, 1fr);
  /* Default: One card per row */
}

.resort-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
  /* width: 230px; */
}

/* 
.resort-card:hover {
  transform: scale(1.02);
} */

.resort-image {
  width: 100%;
  height: 192px;
  overflow: hidden;
  margin-bottom: 16px;
}

.resort-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resort-info {
  padding: 12px;
  color: #333;
}

.resort-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.resort-header h2 {
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.detail-btn {
  padding: 4px 10px;
  background-color: #f3f4f6;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.detail-btn:hover {
  background-color: #e5e7eb;
}

.location {
  display: flex;
  align-items: flex-start;
  gap: 8px;
}

.location-icon {
  width: 20px;
  height: 20px;
  margin-top: 4px;
  flex-shrink: 0;
}

.location p {
  font-size: 14px;
  margin: 0;
  line-height: 1.5;
}

/* Responsive Styles */
@media (min-width: 768px) {
  .resort-list {
    grid-template-columns: repeat(2, 1fr);
    /* 2 cards per row on tablets */
  }

  .resort-listing {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .resort-list {
    grid-template-columns: repeat(3, 1fr);
    /* 3 cards per row on desktops */
  }

  .slider {
    height: 360px;
  }
}

/* For small phones */
@media (max-width: 600px) {
  .slider {
    height: 200px;
  }

  .resort-image {
    height: 160px;
  }

  .resort-header h2 {
    font-size: 16px;
  }

  .location p {
    font-size: 13px;
  }

  .detail-btn {
    font-size: 13px;
    padding: 3px 10px;
  }
}

/* Very small screens */
@media (max-width: 375px) {
  .slider {
    height: 180px;
  }

  .resort-list {
    grid-template-columns: repeat(1, 1fr);
    /* 3 cards per row on desktops */
  }

  .resort-image {
    height: 140px;
  }

  .resort-header h2 {
    font-size: 14px;
  }

  .location p {
    font-size: 12px;
  }

  .detail-btn {
    font-size: 12px;
    padding: 2px 8px;
  }
}

.button-group {
  display: flex;
  gap: 8px;
}

.redirect-btn {
  padding: 4px 12px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.redirect-btn:hover {
  background-color: #45a049;
}

.slider-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(255, 255, 255, 0.5);
  border: none;
  cursor: pointer;
  border-radius: 50%;
  font-size: 18px;
}

.slider-btn:hover {
  background: rgba(255, 255, 255, 0.8);
}

.prev {
  left: 10px;
}

.next {
  right: 10px;
}
</style>