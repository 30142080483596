<template>

    <div class="travel-package">
        <div>
            <RegisterBuyPackageComponent />
        </div>
    </div>

</template>

<script>
import RegisterBuyPackageComponent from '@/components/TravelPackage/RegisterBuyPackageComponent.vue';
export default {
    components: {
        RegisterBuyPackageComponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>