<template>
  <div class="gallers">
    <div class="loading" v-if="loading">
      <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
      <div class="vet-memvers">
        <div class="containers">
          <div class="vet_men">{{ $t('message.vetMembershipCard') }}</div>
          <div class="row mt-3">
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
              <img src="@/assets/about/10.jpg" style="width: 100%;" alt="">
            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
              <span>{{ $t('message.vetMembershipCard1') }}</span><br>
              <span>{{ $t('message.vetMembershipCard2') }}</span><br>
              <span>{{ $t('message.vetMembershipCard3') }}</span>
              <p style="color: #DE5D0A;font-size: 16px;margin-top: 10px;text-decoration: underline;">
                {{ $t('message.note') }}</p>
              <ul>
                <li>{{ $t('message.vetMembershipNote1') }}</li>
                <li>{{ $t('message.vetMembershipNote2') }}</li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5 col-md-5 col-sm-6 col-xs-12">
              <img src="@/assets/about/10.jpg" style="width: 100%;" alt="">
            </div>
            <div class="col-lg-7 col-md-7 col-sm-6 col-xs-12">
              <h3>{{ $t('message.howRegisterMembership') }}</h3>
              <ul style="padding-top: 20px;">
                <li>{{ $t('message.howRegisterMembership1') }}</li>
                <li>{{ $t('message.howRegisterMembership2') }}</li>
                <li>{{ $t('message.howRegisterMembership3') }}</li>
                <li>{{ $t('message.howRegisterMembership4') }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <inc-footer />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      reloadGallers: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    localStorage.setItem("reloadHome", "false");
    localStorage.setItem("reloadCompanyProfile", "false");
    localStorage.setItem("reloadRetal", "false");
    localStorage.setItem("reloadRestore", "false");
    localStorage.setItem("reloadBlog", "false");
    localStorage.setItem("reloadTerm", "false");
    localStorage.setItem("reloadPrivacy", "false");

    this.scrollToTop();
    this.reloadGallers = localStorage.getItem("reloadGallers");
    if (this.reloadGallers != "true") {
      localStorage.setItem("reloadGallers", "true");
    }
    if (localStorage.getItem("reloadGallers") == "true") {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style scoped>
.vet-memvers {
  padding-top: 120px;
  padding-bottom: 50px;
}

.vet_men {
  color: #DE5D0A;
  font-size: 36px;
  font-weight: 600;
  text-align: center;
}
</style>