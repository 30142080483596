<template>
  <v-row v-if="!shouldHideDialog" justify="center">
    <v-dialog class="containers ads-popup" v-model="showPopup" persistent style="z-index: 20020 !important;">
      <div style="width: 100%; position: relative;" class="m-auto-o">
        <vueper-slides ref="vueperSlides" :dragging-distance="50" :arrows="false" :bullets="true"
          :current-slide="currentImageIndex" style="height: 100%;" :touch-dragging="true" :key="slideshowKey">
          <vueper-slide v-for="(slide, i) in images" :key="i" :image="slide" class="slide-image"
            style="height: 100%; background-size: cover; " />
        </vueper-slides>


        <div class="d-flex position-absolute">
          <div class="mr-2 mt-1"> SKIP {{ remainingTime }}</div>
          <div class="box-x" @click="closePopup">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>

  <v-row v-else justify="center">
    <v-dialog v-model="showPopup" persistent max-width="100%" style="z-index: 20020 !important;">
      <div style="width: 100%; height:100%; position: relative;" class="m-auto-o">
        <img :src="currentImage" class="mb-img" alt="Image" style="width:100%; height: 100%;">
        <div class="d-flex position-absolute">
          <div class="mr-2 mt-1"> SKIP {{ remainingTime }}</div>
          <div class="box-x" @click="closePopup">
            <i class="fas fa-times"></i>
          </div>
        </div>
      </div>
    </v-dialog>
  </v-row>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
export default {
  components: {
    VueperSlides,
    VueperSlide
  },
  data() {
    return {
      showPopup: false,
      remainingTime: 6, // Countdown in seconds
      intervalId: null,
      slideshowIntervalId: null,
      currentImageIndex: 0,
      slideshowKey: 0, // Key to force re-rendering
      images: [
        require('@/assets/carousel/Ads-VETair-bus-KH.jpg'),
  
      ],
      setAdspopup: localStorage.getItem('adspopup')
    };
  },
  computed: {
    currentImage() {
      return this.images[this.currentImageIndex];
    },
    shouldHideDialog() {
      // return this.$vuetify.breakpoint.xsOnly;
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.width < 500;
    },
  },
  watch: {
    currentImageIndex() {
      this.slideshowKey++; // Increment key to force re-render
    }
  },
  methods: {
    startTimer() {
      this.intervalId = setInterval(() => {
        if (this.remainingTime > 1) {
          this.remainingTime--;
        } else {
          this.closePopup();
        }
      }, 1000); // Update every second
    },
    // calculateOddEven(number) {
    //   return number % 2 === 0 ? 0 : 1;
    // },
    resizeVueperSlides() {
      this.$nextTick(() => {
        if (this.$refs.vueperSlides) {
          this.$refs.vueperSlides.$emit('resize');
        }
      });
    },
    startSlideshow() {
      this.slideshowIntervalId = setInterval(() => {
        this.currentImageIndex = (this.currentImageIndex + 1) % this.images.length;
        this.$nextTick(() => {
          if (this.$refs.vueperSlides) {
            this.$refs.vueperSlides.goToSlide(this.currentImageIndex);
          }
        });
      }, 3000); // Change image every 3 seconds
    },
    stopSlideshow() {
      clearInterval(this.slideshowIntervalId);
    },
    closePopup() {
      clearInterval(this.intervalId);
      this.stopSlideshow();
      this.showPopup = false;
      this.removefixscroll();
    },
    fixscrollshow() {
      document.querySelector('html').classList.add('fix-scroll');
    },
    removefixscroll() {
      document.querySelector('html').classList.remove('fix-scroll');
    },
    showPopupOnlyone() {
      if (this.setAdspopup === '1') {
        this.showPopup = false;
      } else {
        localStorage.setItem('adspopup', '1');
        setTimeout(() => {
          this.startTimer();
          this.startSlideshow();
          this.showPopup = true;
          this.fixscrollshow();
        }, 1500);
      }
    },
    handleBeforeUnload() {
      localStorage.setItem('adspopup', '2');
    }
  },

  scrollToTop() {
    window.scrollTo(0, 0);
  },
  created() {
    localStorage.setItem('adspopup', '1');
    window.addEventListener('beforeunload', this.handleBeforeUnload);
  },
  mounted() {
    // this.showPopupOnlyone();
    this.showPopupOnlyone();
    window.addEventListener('resize', this.resizeVueperSlides);
  },
  beforeDestroy() {
    // window.removeEventListener('beforeunload', this.handleBeforeUnload);
    // this.stopSlideshow();
    window.removeEventListener('resize', this.resizeVueperSlides); // Clean up event listener
    this.stopSlideshow();
  }
};
</script>

<style scoped>

/* Base styles for v-dialog */
:deep(.v-dialog) {
  /* max-width: 75% !important;
  width: 100% !important;
  margin: 0 auto !important; */
  width: 100% !important;
  height: auto !important;
  box-shadow: none !important;
}

/* Update image display styles */


.mb-img {
  width: 100%;
  height: 100%;
  object-fit: contain !important;
}

/* 4K and Ultra-wide Screens */
@media (min-width: 3000px) {
  :deep(.v-dialog) {
    max-width: 1800px !important;
  }
  .m-auto-o {
    height: calc(90vh - 50px);
    width: 100%;
  }
}

/* Large Desktop Screens */
@media (min-width: 2500px) and (max-width: 2999px) {
  :deep(.v-dialog) {
    max-width: 1400px !important;
  }
  .m-auto-o {
    height: calc(85vh - 50px);
    width: 100%;
  }
}

/* Standard Desktop Screens */
@media (min-width: 1904px) and (max-width: 2499px) {
  :deep(.v-dialog) {
    max-width: 1200px !important;
  }
  .m-auto-o {
    height: calc(80vh - 50px);
    width: 100%;
  }
}

/* Small Desktop/Large Laptop */
@media (min-width: 1540px) and (max-width: 1903px) {
  :deep(.v-dialog) {
    max-width: 1250px !important;
  }
  .m-auto-o {
    height: calc(90vh - 50px);
    width: 100%;
  }
}

/* Standard Laptop */
@media (min-width: 1200px) and (max-width: 1539px) {
  :deep(.v-dialog) {
    max-width: 1100px !important;
  }
  .m-auto-o {
    height: calc(80vh - 50px);
    width: 100%;
  }
}

/* Small Laptop/Large Tablet */
@media (min-width: 1024px) and (max-width: 1199px) {
  :deep(.v-dialog) {
    max-width: 950px !important;
  }
  .m-auto-o {
    height: calc(70vh - 50px);
    width: 100%;
  }
}

/* Tablet Landscape */
@media (min-width: 768px) and (max-width: 1023px) {
  :deep(.v-dialog) {
    max-width: 800px !important;
  }
  .m-auto-o {
    height: calc(60vh - 50px);
    width: 100%;
  }
}

/* Tablet Portrait */
@media (min-width: 600px) and (max-width: 767px) {
  :deep(.v-dialog) {
    max-width: 600px !important;
  }
  .m-auto-o {
    height: calc(45vh - 50px);
    width: 100%;
  }
}

/* Large Mobile */
@media (min-width: 480px) and (max-width: 599px) {
  :deep(.v-dialog) {
    max-width: 450px !important;
  }
  .m-auto-o {
    height: calc(30vh - 50px);
    width: 100%;
  }
}

/* Medium Mobile */
@media (min-width: 380px) and (max-width: 479px) {
  :deep(.v-dialog) {
    max-width: 360px !important;
  }
  .m-auto-o {
    height: calc(30vh - 50px);
    width: 100%;
  }
}

/* Small Mobile */
@media (max-width: 379px) {
  :deep(.v-dialog) {
    max-width: 320px !important;
  }
  .m-auto-o {
    height: calc(40vh - 50px);
    width: 100%;
  }
}

/* Landscape Mode Optimization */
@media (max-height: 600px) and (orientation: landscape) {
  .m-auto-o {
    height: calc(85vh - 50px);
  }
  .v-dialog--active {
    margin-top: 20px !important;
  }
}

.v-dialog__content--active {
  z-index: 1000000000 !important;
}

.v-dialog--active {
  margin-top: 5vh !important;
  transition: all 0.3s ease-in-out;
}

.position-absolute {
  position: absolute;
  z-index: 9999;
  right: 15px;
  top: 10px;
  transition: all 0.2s ease;
}

.box-x {
  width: 30px;
  height: 30px;
  background: rgba(255, 255, 255, 0.9);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  transition: all 0.2s ease;
  /* box-shadow: 0 2px 4px rgba(0,0,0,0.1); */
}

.box-x i {
  color: #333;
  font-size: 18px;
  transition: all 0.2s ease;
}

.box-x:hover {
  background: #fff;
  transform: scale(1.1);
  /* box-shadow: 0 3px 6px rgba(0,0,0,0.15); */
}

/* High-DPI Screens */


</style>
<!-- </style> -->