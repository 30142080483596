import { render, staticRenderFns } from "./ProductService.vue?vue&type=template&id=5821f993&scoped=true"
import script from "./ProductService.vue?vue&type=script&lang=js"
export * from "./ProductService.vue?vue&type=script&lang=js"
import style0 from "./ProductService.vue?vue&type=style&index=0&id=5821f993&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5821f993",
  null
  
)

export default component.exports