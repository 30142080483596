<template>

    <div class="travel-package">
        <div>
            <PaymentForTravelPacakage />
        </div>
    </div>

</template>

<script>
import PaymentForTravelPacakage from '@/components/TravelPackage/PaymentForTravelPacakage.vue';
export default {
    components: {
        PaymentForTravelPacakage,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>