<template>
    <div class="success-page-wrapper">
        <PaymentTravelPKSuccess />
    </div>
</template>

<script>
import PaymentTravelPKSuccess from '@/components/TravelPackage/PaymentTravelPackegeSuccess.vue'

export default {
    name: 'payment-travel-pksuccess',
    components: {
        PaymentTravelPKSuccess,
    },
}
</script>

<style scoped>
.success-page-wrapper {
    position: relative;
    min-height: 100vh;
}

.footer-container {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>