<template>
  <div class="gallers">
    <div class="containers">
      <div>
        <h2 class="title-head">VET Gallery</h2>
      </div>
      <div class="content" v-for="(gallary, i) in dataGallery" :key="i">
        <div>
          <h2 class="title">{{ gallary.name }}</h2>
          <div class="gallery-grid">
            <div class="gallery-item" v-for="(boxItem, j) in gallary.box" :key="j">
              <div class="img-box-head">
                <div class="img-box" @click="openMypopup(boxItem.clidenImage)">
                  <img :src="boxItem.image" alt="" />
                </div>
                <div class="title-bus">
                  <span>{{ boxItem.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-row justify="center" style="z-index: 20020 !important; background-color: #ffffff !important">
      <v-dialog v-model="showPopup" persistent :max-width="dialogWidth" class="gallery-dialog">
        <v-card class="dialog-card">
          <div class="dialog-container">
            <div class="box-close-container d-flex justify-end">
              <div class="box-close" @click="closeMyPopup">
                <span>Close</span>
              </div>
            </div>
            <div class="img-popup">
              <vueper-slides fractions arrow="true" :height="slideHeight" class="height-img"
                style="border-radius: 6px; position: relative">
                <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image"
                  style="height: 100%; border-radius: 6px; background-image: cover;" />
                <template #arrow-left>
                  <div class="arrow left-arrow d-flex justify-center align-center">
                    <img src="@/assets/travel_package/left-chevron.svg" alt="Left Arrow" width="30px" />
                  </div>
                </template>
                <template #arrow-right>
                  <div class="arrow right-arrow d-flex justify-center align-center">
                    <img src="@/assets/travel_package/right-chevron.svg" alt="Right Arrow" width="30px" />
                  </div>
                </template>
              </vueper-slides>
            </div>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      showPopup: false,
      page: 1,
      slides: [],
      dataGallery: [
        {
          id: 1,
          name: 'Vireak Buntham Express Travel',
          box: [
            {
              name: "Hotel Bus",
              image: require("@/assets/galleries/vet/Hotel_Bus/Cover/Hotel_Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Hotel_Bus/Slide/Hotel Bus-06.jpg"),
                },

              ]
            },
            {
              name: "Luxury Hotel Bus",
              image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Cover/Luxury Hotel Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Hotel_Bus/Slide/Luxury Hotel Bus-07.jpg"),
                },

              ]
            },
            {
              name: "Luxury Coaster",
              image: require("@/assets/galleries/vet/Luxury_Coaster/Cover/Luxury Coaster_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Coaster/Slide/Luxury Coaster-07.jpg"),
                },

              ]
            },
            {
              name: "Luxury Van-H350",
              image: require("@/assets/galleries/vet/Luxury_Van-H350/Cover/Classic Hyundai Solati H350_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-07.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury_Van-H350/Slide/Classic Hyundai Solati H350-04.jpg"),
                },

              ]
            },
            {
              name: "Luxury Van-H350",
              image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Cover/Luxury Van-Hiace_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-05.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Luxury-Van-Hiace/Slide/Luxury Van-Hiace-06.jpg"),
                },


              ]
            },

          ]

        },
        {
          id: 2,
          name: 'VET Air Bus Express',
          box: [
            {
              name: "Air Bus",
              image: require("@/assets/galleries/vet/Air_Bus/Cover/Air Bus_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-06.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-07.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-08.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-09.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/Air_Bus/Slide/Air Bus-10.jpg"),
                },
              ]
            },


          ]

        },
        {
          id: 3,
          name: 'Buva Sea Cambodia',
          box: [
            {
              name: "SpeedBoat",
              image: require("@/assets/galleries/vet/SpeedBoat/Cover/SpeedBoat_Cover.jpg"),
              clidenImage: [
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-01.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-03.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-04.jpg"),
                },
                {
                  image: require("@/assets/galleries/vet/SpeedBoat/Slide/SpeedBoat-05.jpg"),
                },

              ]
            },


          ]

        },

      ],
      dialogWidth: '75%',
      // slideHeight: '500px'
    };
  },
  methods: {
    fixscrollshow() {
      const htmlElement = document.querySelector("html");
      htmlElement.classList.add("fix-scroll");
    },
    removefixscroll() {
      const htmlElement = document.querySelector("html");
      htmlElement.classList.remove("fix-scroll");
    },
    openMypopup(images) {
      this.showPopup = true;
      this.slides = images;
      this.fixscrollshow();
    },
    closeMyPopup() {
      this.showPopup = false;
      this.removefixscroll();
    },
    handleResize() {
      const width = window.innerWidth;

      if (width >= 2649) {
        this.dialogWidth = '65%';
      }
      else if (width >= 1900) {
        this.dialogWidth = '65%';
      }
      else if (width >= 1700) {
        this.dialogWidth = '75%';
      }
      else if (width >= 1200) {
        this.dialogWidth = '75%';
      }
      else if (width >= 768) {
        this.dialogWidth = '75%';
      }
      else if (width >= 480) {
        this.dialogWidth = '100%';
      }
      else {
        this.dialogWidth = '100%';
      }
    }
  },
  created() {
    this.removefixscroll();
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style scoped>
.gallers {
  margin-top: 100px;
  padding-bottom: 75px;
}

.title-head {
  color: #000000 !important;
}

.content {
  padding-top: 30px;
}

.right-arrow {
  position: absolute;
  right: 2% !important;
}

.left-arrow {
  position: absolute;
  left: 2% !important;
}

.arrow:hover {
  background-color: #fd7a23;
}

.arrow {
  font-size: 24px;
  color: white;
  background-color: #fd7a23;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
}

.title {
  color: #de5d0a;
  padding-bottom: 6px;
}

.title-bus {
  padding: 9px 20px 10px 20px;
}

.pad-lap {
  padding-right: 0px;
}

.title-bus span {
  color: #2f354d;
  font-weight: 600;
}

.img-box {
  cursor: pointer;
}

.img-box img {
  object-fit: cover;
}

.height-img {
  height: 500px;
}

.box-close {
  width: 100px;
  color: white;
  background: #e26a6a;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 18px;
  cursor: pointer;
}

.box-close span {
  font-weight: 600;
  font-size: 16px;
}

.width-max {
  max-width: 60%;
}


.screen-large {
  display: block !important;
}

.screen-mobile {
  display: none !important;
}

.v-dialog__content--active {
  z-index: 1000000000 !important;
}

@media (width < 600px) {
  .pad-lap {
    padding-right: 11px !important;
  }

  .height-img {
    height: 250px;
  }
}

.gallery-dialog {
  z-index: 20020 !important;
}

.dialog-card {
  padding: 16px 0;
  background-color: #ffffff !important;
}

.dialog-container {
  width: 96%;
  margin: 0 auto;
}

/* Responsive styles for the dialog */
@media (min-width: 1900px) {

  /* .box-close {
    width: 120px;
    padding: 16px 0;
  } */
  .height-img {
    height: 600px;
  }

  .box-close span {
    font-size: 18px;
  }

  .arrow {
    padding: 7px;
  }

  .arrow img {
    width: 35px;
  }
}

@media (max-width: 1200px) {
  .dialog-container {
    width: 98%;
  }

  /* .box-close {
    width: 90px;
    padding: 12px 0;
  } */
}

@media (max-width: 768px) {
  .dialog-card {
    padding: 12px 0;
  }

  /* .box-close {
    width: 80px;
    padding: 10px 0;
  } */

  .box-close span {
    font-size: 14px;
  }

  .arrow {
    padding: 8px;
  }

  .arrow img {
    width: 25px;
  }
}

@media (max-width: 480px) {
  .dialog-container {
    width: 100%;
  }

  /* .box-close {
    width: 70px;
    padding: 8px 0;
  } */

  .box-close span {
    font-size: 13px;
  }

  .arrow {
    padding: 6px;
  }

  .arrow img {
    width: 20px;
  }
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin: 0px;
}

.gallery-item {
  width: 100%;
}

.img-box-head {
  background-color: white;
}

.img-box {
  background-color: #e5e7eb;
  cursor: pointer;
}

.img-box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.title-bus {
  background-color: #e5e7eb;
  padding: 9px 20px 10px 20px;
}
</style>