<template>

    <div>
        <div>
            <ForgotPasswordComponent />
        </div>
    </div>

</template>

<script>
import ForgotPasswordComponent from '@/components/users/ForgotPasswordComponent.vue';
export default {
    components: {
        ForgotPasswordComponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>