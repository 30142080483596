<template>
    <div>
        <div class="gotopbtn" @click="toTop" :class="{ 'topScroll': !narbarTop.topOfPage }"><i
                class="fa-sharp fa-solid fa-arrow-up"></i></div>
        <div>
            <div class="loading" v-if="loading"
                style="height: 600px;width: 100%;display: flex;align-items: center;justify-content: center;z-index: 100000;">
                <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular></div>
            <div v-else class="restore-contentes">
                <div class="contact-content">
                    <section style="margin-top: 50px;">
                        <!-- <img class="img-fluid rounded" src="../assets/resort/retal-service.jpg" alt="Virak Buntham Resort"> -->
                    </section>

                    <div id="content">
                        <div class="row">
                            <div class="col-md-6 mt-4 mt-md-0">
                                <div class="bg-light shadow-md rounded p-4">
                                    <h2 class="text-6">Contact Us</h2>
                                    <div class="featured-box style-1">
                                        <div class="featured-box-icon text-primary"> <i
                                                class="fas fa-map-marker-alt"></i></div>
                                        <h3>Vireak Buntham Express</h3>
                                        <p>#29 St. 47, Songkat Sras Jork, Khan Donpenh, Phnom Penh, Cambodia</p>
                                    </div>
                                    <div class="featured-box style-1">
                                        <div class="featured-box-icon text-primary"> <i class="fas fa-phone"></i> </div>
                                        <h3>Telephone</h3>
                                        <p>081 911 911</p>
                                    </div>
                                    <div class="featured-box style-1">
                                        <div class="featured-box-icon text-primary"> <i class="fas fa-envelope"></i>
                                        </div>
                                        <h3>Email</h3>
                                        <p>support@vireakbuntham.com</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="bg-light shadow-md rounded h-100 p-3">
                                    <iframe class="h-100 w-100"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3908.6053871463396!2d104.91812631494577!3d11.580121247010268!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMTHCsDM0JzQ4LjQiTiAxMDTCsDU1JzEzLjEiRQ!5e0!3m2!1sen!2sin!4v1552721202513"
                                        allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {
            loading: true,
            narbarTop: {
                topOfPage: true
            },
            reloadContact: ""
        }
    },
    methods: {
        handleScroll() {
            if (window.pageYOffset > 0) {
                if (this.narbarTop.topOfPage) this.narbarTop.topOfPage = false
            } else {
                if (!this.narbarTop.topOfPage) this.narbarTop.topOfPage = true
            }
        },
        toTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    created() {
        localStorage.setItem('reloadHome', "false");
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadGall', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy', 'false');

        this.reloadContact = localStorage.getItem('reloadContact');
        if (this.reloadContact != 'true') {
            localStorage.setItem('reloadContact', 'true');
        }
        if (localStorage.getItem('reloadContact') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1500);
        }
        this.scrollToTop();
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
}
</script>

<style>
.restore-contentes {
    padding-top: 50px;
    padding-left: 250px;
    padding-right: 250px;
    display: flex;
    justify-content: center;
}

.contact-content {
    width: 1000px;
}

@media(max-width: 850px) {
    .restore-contentes {
        padding-left: 68px;
        padding-right: 68px;
    }
}

@media(max-width: 961px) {
    .restore-contentes {
        padding-top: 90px;
    }
}

@media(max-width: 600px) {
    .restore-contentes {
        padding-left: 50px;
        padding-right: 50px;
    }
}

@media(max-width: 422px) {
    .restore-contentes {
        padding-left: 20px;
        padding-right: 20px;
    }
}

.rounded {
    border-radius: 0.25rem !important;
}

.img-fluid {
    max-width: 100%;
    height: auto;
}

.text-6 {
    font-size: 24px !important;
    font-size: 1.50rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #0c2f54;
    font-family: "Poppins", sans-serif;
}

.featured-box.style-1,
.featured-box.style-2,
.featured-box.style-3 {
    padding-left: 50px;
    padding-top: 8px;
}

.featured-box {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: auto;
    position: relative;
}

.featured-box.style-1 .featured-box-icon,
.featured-box.style-2 .featured-box-icon,
.featured-box.style-3 .featured-box-icon {
    position: absolute;
    top: 0;
    left: 0;
    margin-bottom: 0;
    font-size: 30px;
    -ms-flex-pack: center !important;
    justify-content: center !important;
    text-align: center;
}

.featured-box .featured-box-icon {
    display: inline-block;
    font-size: 35px;
    height: 45px;
    line-height: 45px;
    padding: 0;
    width: 45px;
    margin-top: 0;
    margin-bottom: 12px;
    color: #546d89;
    border-radius: 0;
}

.text-primary {
    color: #007bff !important;
}

.featured-box h3,
.featured-box h4 {
    font-size: 1.25rem;
    margin-bottom: 10px;
    font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #0c2f54;
    font-family: "Poppins", sans-serif;
}

p {
    line-height: 1.9;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.p-3 {
    padding: 1rem !important;
}

.h-100 {
    height: 100% !important;
}

.w-100 {
    width: 100% !important;
}

iframe {
    border: 0 !important;
}
</style>