<template>
    <div>
        <div>
            <HeaderComponent />
            <LoginComponent />
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import LoginComponent from '@/components/users/LoginComponent.vue';
export default {
    components: {
        HeaderComponent,  // Import HeaderComponent.vue from components/Headers/HeaderComponent.vue
        LoginComponent,
    },
    data() {
        return {
            loading: true,
        }
    },
    methods: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>