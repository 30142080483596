<template>
    <div class="shedule">
        <div class="desktop">
            <SheduleList />
        </div>
        <div class="mobile-app">
            <MoblieScreen />
        </div>

    </div>
</template>

<script>
import SheduleList from '@/components/Shedule/SheduleList.vue'
import MoblieScreen from '@/components/moblie/moblieScreen.vue';
export default {
    components: {
        SheduleList,
        MoblieScreen
    },

    data() {
        return {

        }
    },
    methods: {},

    mounted() {
        window.onpopstate = () => {
            localStorage.removeItem("seat");
            localStorage.removeItem("seatlabel");
        };
    },

    created() { }

}
</script>

<style scoped>
.mobile-app {
    display: none;
}

@media(width < 960px) {
    .desktop {
        display: none;
    }

    .mobile-app {
        display: block;
    }
}
</style>