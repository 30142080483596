import { render, staticRenderFns } from "./ContactPage.vue?vue&type=template&id=2f651449"
import script from "./ContactPage.vue?vue&type=script&lang=js"
export * from "./ContactPage.vue?vue&type=script&lang=js"
import style0 from "./ContactPage.vue?vue&type=style&index=0&id=2f651449&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports