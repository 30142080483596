<template>
  <div class="gallers">
    <div class="loading" v-if="loading">
      <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
      <div class="vet-vehicle">
        <div class="containers">
          <ul class="custom-list">
            <li>{{ $t('message.vetVehicletype1') }}</li>
            <div>
              <img src="@/assets/about/Luxury Bus.png" alt="Luxury Bus">
            </div>
            <li>{{ $t('message.vetVehicletype2') }}</li>
            <div>
              <img src="@/assets/about/Truck.png" alt="Truck">
            </div>
            <li>{{ $t('message.vetVehicletype3') }}</li>
            <div>
              <img src="@/assets/about/Boat.png" alt="Boat">
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      reloadGallers: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    localStorage.setItem("reloadHome", "false");
    localStorage.setItem("reloadCompanyProfile", "false");
    localStorage.setItem("reloadRetal", "false");
    localStorage.setItem("reloadRestore", "false");
    localStorage.setItem("reloadBlog", "false");
    localStorage.setItem("reloadTerm", "false");
    localStorage.setItem("reloadPrivacy", "false");

    this.scrollToTop();
    this.reloadGallers = localStorage.getItem("reloadGallers");
    if (this.reloadGallers != "true") {
      localStorage.setItem("reloadGallers", "true");
    }
    if (localStorage.getItem("reloadGallers") == "true") {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style scoped>
.vet-vehicle {
  display: flex;
  margin-top: 140px;
}

.vet-vehicle .custom-list {
  list-style-type: square;
}

.vet-vehicle li {
  color: rgb(47, 29, 29);
  font-size: 22px;
  cursor: pointer;
}

.vet-vehicle li:hover {
  color: #de5d0a;
}

/* @media(min-width: 1740px) {
    .set-heights {
      height: 500px;
    }
  } */
.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}

/* @media(width < 1024px) {
    .set-heights {
      height: 500px;
    }
  } */
@media (width < 450px) {
  .no-result {
    font-size: 16px;
  }

  img {
    width: 86px;
  }

  .vet-vehicle {
    margin-top: 100px;
  }

  .vet-vehicle div img {
    width: 100%;
  }
}
</style>