<template>
  <div class="gallers">
    <div class="loading" v-if="loading">
      <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div>
    <div v-else>
      <VetSubsidiaries />
    </div>
  </div>
</template>

<script>
import VetSubsidiaries from "@/components/profile/VetSubsidiaries.vue";
export default {
  components: {
    VetSubsidiaries

  },
  data() {
    return {
      loading: true,
      narbarTop: {
        topOfPage: true,
      },
      reloadGallers: "",
    };
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    localStorage.setItem("reloadHome", "false");
    localStorage.setItem("reloadCompanyProfile", "false");
    localStorage.setItem("reloadRetal", "false");
    localStorage.setItem("reloadRestore", "false");
    localStorage.setItem("reloadBlog", "false");
    localStorage.setItem("reloadTerm", "false");
    localStorage.setItem("reloadPrivacy", "false");

    this.scrollToTop();
    this.reloadGallers = localStorage.getItem("reloadGallers");
    if (this.reloadGallers != "true") {
      localStorage.setItem("reloadGallers", "true");
    }
    if (localStorage.getItem("reloadGallers") == "true") {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  },
};
</script>

<style scoped></style>