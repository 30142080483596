<template>
    <div class="otp">
        <div>
            <OtpUsercomponent />
        </div>
    </div>
</template>

<script>
import OtpUsercomponent from '@/components/users/OtpUsercomponent.vue';
export default {
    components: {
        OtpUsercomponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>