<template>

    <div class="TravelPackageHistory">
        <div>
            <TravelHistory />
        </div>
    </div>

</template>

<script>
import TravelHistory from '@/components/HistoryTicketComponent/TravelHistory.vue';
export default {
    components: {
        TravelHistory,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped>
.footer-bot {
    position: absolute;
    margin: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
    /* text-align: center; */
}
</style>