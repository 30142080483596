<template>
    <div class="shedule-back">
        <div class="desktop">
            <ListScheduleBack />
        </div>
        <div class="mobile-app">
            <MoblieScreenBack />
        </div>
    </div>
</template>

<script>
import ListScheduleBack from '@/components/Shedule/ListScheduleBack.vue';
import MoblieScreenBack from '@/components/moblie/moblieScreenBack.vue';
export default {
    components: { ListScheduleBack, MoblieScreenBack },

    data() {
        return {

        }
    },
    methods: {},
}
</script>

<style scoped>
.mobile-app {
    display: none;
}

@media(width < 960px) {
    .desktop {
        display: none;
    }

    .mobile-app {
        display: block;
    }
}
</style>