import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../pages/HomePage/HomePage.vue';
import CompanyProfile from '../pages/AboutUs/CompanyProfile.vue';
import VetBackground from '../pages/AboutUs/VetBackground.vue';
import MissionPage from '../pages/AboutUs/MissionPage.vue';
import MilestonPage from '../pages/AboutUs/MilestonPage.vue';
import VET_Values from '../pages/AboutUs/VET_Values.vue';
import BoardingPage from '../pages/AboutUs/BoardingPage.vue';
import ProductService from '../pages/AboutUs/ProductService.vue';
import Restore from '../pages/ResortPage/RestorePage.vue';
import rental from '../pages/RentalPage/RentalPage.vue';
import BookingDetail from '../pages/BookingDeilPage/BookingDetail.vue';
import Galleries from '../pages/GalleryPage/GalleriesPage.vue';
import GalleriesDetail from '../pages/GalleryPage/GalleriesDetailPage.vue';
import Blog from '../pages/BlogPage/BlogPage.vue';
import NewBlog from '../pages/BlogPage/NewBlog.vue';
import Contact from '../pages/ContactPage/ContactPage.vue';
import Term from '../pages/FooterPage/TermPage.vue';
import Policy from '../pages/FooterPage/PrivacyPage.vue';
import BusList from '../pages/ShedulePage/BusListPage.vue';
import BusListBack from '../pages/ShedulePage/BusListBackPage.vue';
import ChooseSeat from '../pages/ChooseSeatPage/ChooseSeatPage.vue';
import ChooseSeatBack from '../pages/ChooseSeatPage/ChooseSeatBackPage.vue';
import Tripinfo from '../pages/TripinfoPage/TripInfo.vue';
import PassengerInfo from '../pages/PassengerPage/PassgerPage.vue';
import PaymentCart from '../pages/Payment/PaymentCart.vue';
import PaymentWing from '../pages/Payment/PaymentWing.vue';
import PaymentSuccess from '../pages/Payment/PaymentSuccess.vue';
import NotFound from "@/pages/NotFound.vue";
import VetSubsidiaries from "../pages/AboutUs/VetSubsidiaries";
import VetVehicleType from "../pages/AboutUs/VetVehicleType";
import WhyPartnerWithUs from "../pages/AboutUs/WhyPartnerWithUs";
import VetMembershipCard from "../pages/AboutUs/VetMembershipCard";
import FeedBack from "../pages/FeedBack";
import TermVetExpress from "../pages/TermVetExpress";
import TermVetScanner from "../pages/TermVetScanner";
import LoginUser from "../pages/Users/LoginUser.vue";
import RegisterUser from "../pages/Users/RegisterUser.vue";
import ComfirmOtp from "../pages/Users/ComfirmOtp.vue";
import ForgotPasswordUser from "../pages/Users/ForgotPasswordUser.vue";
import ResetNewPasswordUser from "../pages/Users/ResetNewPasswordUser.vue";
import TravelPackage from "../pages/TravelPackage/TravelPackage.vue";
import RegisterBuyPackage from "../pages/TravelPackage/RegisterBuyPackage.vue";
import ProfileAccount from '../pages/Users/ProfileAccount.vue';
import TravelHistory from '../pages/HistoryTicket/TravelPackageHistory.vue';
import TicketHistory from '../pages/HistoryTicket/TicketHistoryPage.vue';
import PaymentForTravelPacakage from "../pages/TravelPackage/PaymentTravelPackage.vue";
import PaymentTravelPackegSuccess from "../pages/TravelPackage/PagePaymentTravelPackegeSuccess.vue";
import termsConditionsTicket from '@/components/moblie/termsConditionTicket.vue'
import MobileResortPage from '@/components/moblie/mobileResort-page.vue';
import ListCompanySubsidiary from '@/components/subsidiary/ListCompanySubsidiary.vue';
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/terms-conditions-ticket',
    name: 'terms-conditions-ticket',
    component: termsConditionsTicket,
    meta: {
      hideFooterNavbar: true, // Add this metadata
    },
  },
  {
    path: '/mobileresort-page',
    name: 'mobileresort-page',
    component: MobileResortPage,
    meta: {
      hideFooterNavbar: true, // Add this metadata
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginUser,
    meta: {
      hideNavbar: true, // Hide only the navbar
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: RegisterUser,
    meta: {
      hideNavbar: true, // Hide only the navbar
    },
  },
  {
    path: '/comfirm-otp',
    name: 'comfirm-otp',
    component: ComfirmOtp,
    // meta: {
    //   hideNavbar: true, // Hide only the navbar
    // },
  },
  {
    path: '/forgot-password',
    name: 'forget-password',
    component: ForgotPasswordUser
  },
  {
    path: '/reset-new-password',
    name: 'reset-new-password',
    component: ResetNewPasswordUser
  },
  {
    path: '/profile-account',
    name: 'profile-account',
    component: ProfileAccount
  },
  {
    path: '/history-travel-package',
    name: 'history-travel-package',
    component: TravelHistory
  },
  {
    path: '/ticket-history',
    name: 'history-ticket',
    component: TicketHistory
  },
  {
    path: '/about-us',
    name: 'about-us',
    component: CompanyProfile
  },
  {
    path: '/vet-background',
    name: 'vet-background',
    component: VetBackground
  },
  {
    path: '/vision-mission',
    name: 'mission',
    component: MissionPage
  },
  {
    path: '/milestone',
    name: 'milestone',
    component: MilestonPage
  },
  {
    path: '/vet-value',
    name: 'vet-value',
    component: VET_Values
  },
  {
    path: '/eco-message',
    name: 'boarding',
    component: BoardingPage
  },
  {
    path: '/product-service',
    name: 'product-service',
    component: ProductService
  },
  {
    path: '/accommodation',
    name: 'resort',
    component: Restore
  },
  {
    path: "/vehicle-rental",
    name: "rental",
    component: rental
  },
  {
    path: "/travel-package",
    name: "travel-package",
    component: TravelPackage
  },
  {
    path: "/register-buy-package/:data?",
    name: "register-buy-package",
    component: RegisterBuyPackage
  },
  {
    path: "/payment-travel-pk/:data?",
    name: "payment-travel-pk",
    component: PaymentForTravelPacakage
  },
  {
    path: '/booking-detail/:data?',
    name: 'booking-detail',
    component: BookingDetail
  },
  {
    path: '/blog',
    name: 'blog',
    component: Blog
  },
  {
    path: '/new-blog',
    name: 'new-blog',
    component: NewBlog
  },
  {
    path: '/galleries',
    name: 'galleries',
    component: Galleries
  },
  {
    path: '/galleriesDetail/:data?',
    name: 'GalleriesDtail',
    component: GalleriesDetail
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/subsidiaries',
    name: 'VetSubsidiaries',
    component: VetSubsidiaries,
  },
  {
    path: '/vehicle-type',
    name: 'VetVehicleType',
    component: VetVehicleType,
  },
  {
    path: '/partner',
    name: 'WhyPartnerWithUs',
    component: WhyPartnerWithUs,
  },
  {
    path: '/membership-card',
    name: 'VetMembershipCard',
    component: VetMembershipCard,
  },
  {
    path: '/term-condition',
    name: 'Term-page',
    component: Term
  },
  {
    path: '/privacy-policy',
    name: 'Policy',
    component: Policy
  },
  {
    path: '/bus-list',
    name: 'BusList',
    component: BusList
  },
  {
    path: "/tripinfo/:data?",
    name: "tripinfo",
    component: Tripinfo
  },
  {
    path: '/bus-list-back',
    name: 'BusListBack',
    component: BusListBack
  },
  {
    path: "/choose-seat",
    name: "choose-seat",
    component: ChooseSeat
  },
  {
    path: "/choose-seat-back",
    name: "choose-seat-back",
    component: ChooseSeatBack
  },
  {
    path: "/passenger-info",
    name: "passenger-info",
    component: PassengerInfo
  },
  {
    path: "/payment-cart/:data?",
    name: "payment-cart",
    component: PaymentCart
  },
  {
    path: "/payment-wing/:data?",
    name: "payment-wing",
    component: PaymentWing
  },
  {
    path: "/payment-success/:data?",
    name: "payment-success",
    component: PaymentSuccess
  },
  {
    path: "/feed-back/:data?",
    name: "feed-back",
    component: FeedBack
  },
  {
    path: "/term-vet-express",
    name: "term-vet-express",
    component: TermVetExpress
  },
  {
    path: "/term-vet-scanner",
    name: "term-vet-scanner",
    component: TermVetScanner
  },
  {
    path: "/payment-travel-pksuccess",
    name: "payment-travel-pksuccess",
    component: PaymentTravelPackegSuccess
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/subsidiary",
    name: "subsidiary",
    component: ListCompanySubsidiary
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition; // When using browser navigation, scroll to the saved position.
    } else {
      return { x: 0, y: 0 }; // Scroll to the top of the page for new navigation.
    }
  },
})

router.beforeEach((to, from, next) => {
  if(from.name == "payment-cart") {
    if(to.name == "Home" || to.name == "payment-success" || to.name == "payment-wing"){
      next(true);
    }else{
      next(false);
    }
  }else if(from.name == "Home") {
    if(to.name == "BusList" || to.name == "about-us" || to.name == "resort" || to.name == "rental" || to.name == "blog" || to.name == "login" || to.name == "galleries" ||  to.name == "travel-package" || to.name == "profile-account" || to.name == "history-travel-package" || to.name == "history-ticket" || to.name == "subsidiary" || to.name == "Term-page" || to.name == "Policy"){
      if(localStorage.getItem("handleBrowser") == "1") {
        next(false);
      }else{
        next(true);
      }
    }
    
    else{
      next(false);
    }
  }else if(to.name == "payment-travel-pk") {
      if(localStorage.getItem('codeTravelpk') == '' || localStorage.getItem('codeTravelpk') == null){
        next(false);
      }else{
        next(true);
      }
  }else if(from.name == "register-buy-package" ) {
      if(to.name == "travel-package"){
        if(localStorage.getItem('showNewContent') == 1){
          next(true);
        }else{
          next(false);
        }
      }else{
        next(true);
      }
  }else{
    next(true);
  }
})

export default router

