<template>
    <div class="choose">
        <div class="chooseSeat">
            <choose-seat />
        </div>
    </div>
</template>

<script>
import ChooseSeat from '@/components/seats/ChooseSeat.vue';
export default {
    components: {
        ChooseSeat
    },

    data() {
        return {}
    },
    methods: {},
    created() { }
}
</script>

<style scoped></style>