<template>

    <div class="TravelPackageHistory">
        <div>
            <TicketHistoryComponent />
        </div>
    </div>

</template>

<script>
import TicketHistoryComponent from '@/components/HistoryTicketComponent/TicketHistoryComponent.vue';
export default {
    components: {
        TicketHistoryComponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped>
.footer-container {
    position: absolute;
    margin: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-bottom: 10px;
}
</style>