<template>
    <div class="booking-detail">
        <div class="containers">
            <section class="top-below">
                <div class="title-booking">
                    {{ $t('message.bookingDetail') }}
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 col-sm-12 paddingLeftHeader" style="padding-right: 12px;">
                        <div class="card-from">
                            <div class="row">
                                <div class="col-lg-5 col-md-6">
                                    <label for="fullname">{{ $t('message.fullName') }} <span
                                            style="color: #FF0000;">*</span></label><br>
                                    <input type="text" v-model="fullName" :placeholder="$t('message.fullName')"
                                        class="custom_select">
                                </div>
                                <div class="col-lg-5 col-md-6">
                                    <label for="phone_number">{{ $t('message.telephoneNumber') }}<span
                                            style="color: #FF0000;">*</span></label><br>
                                    <input type="tel" v-model="phone" @input="validatePhoneNumber" class="custom_select"
                                        :placeholder="$t('message.telephoneNumber')">
                                    <span v-if="phoneNumberError.phone" style="color: #FF0000;">{{
                                        phoneNumberError.phone }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="journey-type">
                            <div class="title-juurney">
                                <div style="padding-bottom: 10px;">
                                    <span class="title">{{ $t('message.journeyType') }}</span> <span
                                        style="color: #FF0000;">*</span>
                                </div>
                                <v-radio-group class="iphoneScreen1" row mandatory v-model="radioGroup"
                                    @change="checkjourneyType" style="padding-left: 25px;">
                                    <v-radio color="#DE5D0A" :label="$t('message.oneWay')" value="1"></v-radio>
                                    <v-radio color="#DE5D0A" :label="$t('message.roundTrip')" value="2"></v-radio>
                                    <v-radio color="#DE5D0A" :label="$t('message.trip')" value="3"></v-radio>
                                </v-radio-group>
                            </div>
                        </div>
                        <div class="journey-type" style="margin-top: 20px;">
                            <div class="title-juurney">
                                <div style="padding-bottom: 10px;">
                                    <span class="title">{{ $t('message.destination') }}</span> <span
                                        style="color: #FF0000;">*</span>
                                </div>
                                <div class="form-destination">
                                    <div class="row iphoneScreen1" style="padding-left: 30px;">
                                        <div class="col-lg-5 col-md-6">
                                            <div style="position: relative;">
                                                <input type="text" v-model="departureFrom" @click="selectDestination"
                                                    :placeholder="$t('message.selectDeparture')" class="custom_select">
                                                <button type="button" class="button" @click="selectDestination">
                                                    <i class="fa-sharp fa-solid fa-caret-down"></i>
                                                </button>
                                                <div style="position: absolute;top:49px;width: 100%;">
                                                    <div v-if="showListData"
                                                        class="v-menu__content theme--light menuable__content__active v-autocomplete__content"
                                                        v-click-outside="hidePopup"
                                                        style="max-height: 304px;min-width: 100%;transform-origin: left top; z-index: 8;">
                                                        <div v-if="emptyData" role="listbox"
                                                            class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                                            <div role="option"
                                                                class="v-list-item v-list-item--link theme--light">
                                                                <div class="v-list-item__content"
                                                                    style="padding: 13px !important;">
                                                                    <div class="v-list-item__title">No data available
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else role="listbox" v-for="item in proviceCity"
                                                            :key="item.id"
                                                            class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                                            <div role="option"
                                                                class="v-list-item v-list-item--link theme--light"
                                                                @click="onConfirmFrom(departureFromName = item.name, departureFromId = item.id)">
                                                                <div class="v-list-item__content edit-list"
                                                                    style="padding: 13px !important;">
                                                                    <div class="v-list-item__title"
                                                                        @click="onConfirmFrom(departureFromName = item.name, departureFromId = item.id)">
                                                                        {{ item.name }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-6">
                                            <div style="position: relative;">
                                                <input type="text" v-model="destinationTo" @click="selectDestinationTo"
                                                    :placeholder="$t('message.selectArrival')" class="custom_select">
                                                <button type="button" class="button" @click="selectDestinationTo">
                                                    <i class="fa-sharp fa-solid fa-caret-down"></i>

                                                </button>
                                                <div style="position: absolute;top:49px;width: 100%;">
                                                    <div v-if="showListDataTo"
                                                        class="v-menu__content theme--light menuable__content__active v-autocomplete__content"
                                                        v-click-outside="hidePopupTo"
                                                        style="max-height: 304px;min-width: 100%;transform-origin: left top; z-index: 8;">
                                                        <div v-if="emptyData" role="listbox"
                                                            class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                                            <div role="option"
                                                                class="v-list-item v-list-item--link theme--light">
                                                                <div class="v-list-item__content"
                                                                    style="padding: 13px !important;">
                                                                    <div class="v-list-item__title">No data available
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-else role="listbox" v-for="item in proviceCity"
                                                            :key="item.id"
                                                            class="v-list v-select-list v-sheet theme--light v-list--dense theme--light">
                                                            <div role="option"
                                                                class="v-list-item v-list-item--link theme--light"
                                                                @click="onConfirmTo(destinationToName = item.name, destinationToId = item.id)">
                                                                <div class="v-list-item__content edit-list"
                                                                    style="padding: 13px !important;">
                                                                    <div class="v-list-item__title"
                                                                        @click="onConfirmTo(destinationToName = item.name, destinationToId = item.id)">
                                                                        {{ item.name }}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="journey-type" style="margin-top: 20px;">
                            <div class="title-juurney">
                                <div style="padding-bottom: 10px;">
                                    <span class="title">{{ $t('message.departureDate') }}</span> <span
                                        style="color: #FF0000;">*</span>
                                </div>
                                <div class="form-destination">
                                    <div class="row iphoneScreen1" v-if="onWayCheck" style="padding-left: 30px;">
                                        <div class="col-lg-10 col-md-11">
                                            <v-menu v-model="menu" offset-y min-width="auto">
                                                <template v-slot:activator="{ attrs }">
                                                    <div class="reposo-height">
                                                        <input class="custom_select" label="Select Departure Date"
                                                            :placeholder="$t('message.selectDepartureDate')"
                                                            v-model="dateFrom" solo readonly v-bind="attrs"
                                                            @click="menu = true">
                                                        <div @click="menu = true" class="icon-date"
                                                            style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                            <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                                            <i class="fa-regular fa-calendar"
                                                                style="font-size: 18px;"></i>
                                                        </div>
                                                    </div>

                                                </template>
                                                <v-date-picker color="#de5d0a" v-model="dateFrom"
                                                    :min="new Date().toISOString().substr(0, 10)"
                                                    @input="storeDate(dateFrom)"></v-date-picker>
                                            </v-menu>
                                        </div>
                                        <!-- <div class="col-lg-5 col-md-6">
                                                <v-menu v-model="menu2" offset-y min-width="auto">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <div style="position: relative;">
                                                            <input class="reposo-height custom_select"
                                                                placeholder="Return Date"
                                                                v-model="dateTo"
                                                                solo
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                            >
                                                            <div>
                                                                <div @click="clearDates" v-if="clearDate" class="icon-date" style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                                    <img src="@/assets/icon_bars/date.svg" alt="">
                                                                </div> 
                                                                <div v-on="on" v-else class="icon-date" style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                                    <img src="@/assets/icon_bars/date.svg" alt="">
                                                                </div> 
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <v-date-picker color="#de5d0a" v-model="dateTo" :min="new Date(this.dateFrom).toISOString().substr(0, 10)" @input="storeDateTo(dateTo)"></v-date-picker>
                                                </v-menu>
                                            </div> -->
                                    </div>
                                    <div class="row iphoneScreen1" v-if="TripCheck" style="padding-left: 30px;">
                                        <div class="col-lg-5 col-md-6">
                                            <v-menu v-model="menu" offset-y min-width="auto">
                                                <template v-slot:activator="{ attrs }">
                                                    <div class="reposo-height">
                                                        <input class="custom_select" label="Select Departure Date"
                                                            :placeholder="$t('message.selectDepartureDate')"
                                                            v-model="dateFrom" solo readonly v-bind="attrs"
                                                            @click="menu = true">
                                                        <div @click="menu = true" class="icon-date"
                                                            style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                            <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                                            <i class="fa-regular fa-calendar"
                                                                style="font-size: 18px;"></i>
                                                        </div>
                                                    </div>

                                                </template>
                                                <v-date-picker color="#de5d0a" v-model="dateFrom"
                                                    :min="new Date().toISOString().substr(0, 10)"
                                                    @input="storeDate(dateFrom)"></v-date-picker>
                                            </v-menu>
                                        </div>
                                        <div class="col-lg-5 col-md-6">
                                            <v-menu v-model="menu2" offset-y min-width="auto">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <div style="position: relative;">
                                                        <input class="reposo-height custom_select"
                                                            :placeholder="$t('message.selectReturnDate')"
                                                            v-model="dateTo" solo readonly v-bind="attrs" v-on="on">
                                                        <div>
                                                            <div @click="clearDates" v-if="clearDate" class="icon-date"
                                                                style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                                <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                                                <i class="fa-regular fa-calendar"
                                                                    style="font-size: 18px;"></i>
                                                            </div>
                                                            <div v-on="on" v-else class="icon-date"
                                                                style="position: absolute;top: 9px;right: 10px;color: #c4c3c3;">
                                                                <!-- <img src="@/assets/icon_bars/date.svg" alt=""> -->
                                                                <i class="fa-regular fa-calendar"
                                                                    style="font-size: 18px;"></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <v-date-picker color="#de5d0a" v-model="dateTo"
                                                    v-if="this.dateFrom != ''"
                                                    :min="new Date(this.dateFrom).toISOString().substr(0, 10)"
                                                    @input="storeDateTo(dateTo)"></v-date-picker>
                                            </v-menu>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="journey-type" style="margin-top: 20px;">
                            <div class="title-juurney">
                                <div style="padding-bottom: 10px;">
                                    <span class="title">{{ $t('message.amountOfCar') }}</span> <span
                                        style="color: #FF0000;">*</span>
                                </div>
                                <div class="form-destination">
                                    <div class="row iphoneScreen1" style="padding-left: 30px;">
                                        <div class="col-lg-10 col-md-6">
                                            <input type="text" v-model="amount" @input="filterNumeric"
                                                :placeholder="$t('message.amountOfCar')" class="custom_select">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="journey-type" style="margin-top: 20px;">
                            <div class="title-juurney">
                                <div style="padding-bottom: 10px;">
                                    <span class="title">{{ $t('message.remark') }} </span>
                                </div>
                                <div class="form-destination">
                                    <div class="row iphoneScreen1" style="padding-left: 30px;">
                                        <div class="col-lg-10 col-md-6">
                                            <textarea v-model="note" class="custom_select" style="height: 80px;"
                                                :placeholder="$t('message.remark')"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-sm-12" style="padding-left: 12px;padding-right: 12px;">
                        <div class="car-vip">
                            <span style="color: #424245;">{{ $t('message.vehicleTyle') }}:</span> <span
                                style="color: #1D1D1D;font-weight: 600;">{{ nameRen }}</span>
                        </div>
                        <div class="silde-car">
                            <vueper-slides :dragging-distance="50" :arrows="false" height="100%"
                                style="height: 100%;border-radius: 12px;">
                                <vueper-slide style="height: 100%;border-radius: 12px;" v-for="(slide, i) in slidesImg"
                                    :key="i"
                                    :image="slidesImg.length != 0 ? slide.photo : require('@/assets/placeholder-image.jpeg')" />
                            </vueper-slides>
                        </div>
                        <div class="save-submit" @click="saveBook">
                            {{ $t('message.save') }}
                        </div>
                    </div>
                </div>
                <!-- only mobile showing -->
                <v-row v-if="shouldHideDialog" justify="center">
                    <v-dialog v-model="dialogBook" persistent max-width="370px"
                        style="z-index: 1002; background-color: #D9D9D9 !important; border-radius: 10px !important;">
                        <v-card style="background-color: #D9D9D9 !important; border-radius: 10px !important;">
                            <div class="container">
                                <div class="row familyfont d-flex justify-center">
                                    <div class="col-6 text-center">
                                        <div>
                                            <img src="@/assets/alert/ic_tick_green.svg" alt="" style="width: 80%;">
                                        </div>
                                        <div>
                                            <h1 class="titlebox" style="font-size: 27px !important;">សូមអរគុណ</h1>
                                        </div>
                                    </div>
                                </div>
                                <div class="row familyfont d-flex justify-center" style="margin-top: 0px;">
                                    <div class="col-11 text-center">
                                        <div>
                                            <p class="titledescription"
                                                style="font-size: 15px !important; margin-top: -10px !important;">
                                                សូមមេត្ដារង់ចាំ
                                                ក្រុមហ៊ុនយើងខ្ញុំនិងធ្វើការទំនាក់ទំនង ដើម្បីបញ្ជាក់ការជួលរថយន្ដ</p>
                                        </div>
                                        <div>
                                            <p class="titledescription"
                                                style="font-size: 15px !important; margin-top: -10px !important;">
                                                លេខទំនាក់ទំនង៖
                                                010522522 | 081911911</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row familyfont d-flex justify-center" style="margin-top: 0px;">
                                    <div class="col-12" style="margin-left: 35px;">
                                        <div style="margin-top: -21px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ឈ្មោះ : {{ fullName }}
                            </pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">លេខទូរស័ព្ទ : {{ phone }}
                            </pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ចេញពីទិសដៅ : {{
                                departureFrom }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ទៅកាន់ទិសដៅ : {{
                                destinationTo }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃចេញដំណើរ : {{ dateFrom
                                }}</pre>
                                            </p>
                                        </div>
                                        <!-- <div style="margin-top: -12px;" v-if="!onWayCheck">
                                            <p style="font-size: 15px !important;"><pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃត្រលបមកវិញ  :  {{ dateTo }}</pre> </p>
                                        </div> -->
                                        <div style="margin-top: -12px;" v-if="TripCheck">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃត្រលបមកវិញ : {{ dateTo
                                }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ប្រភេទរថយន្ដ : {{ nameRen
                                }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -12px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ចំនួនរថយន្ដ : {{ amount }}
                            </pre>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="row familyfont d-flex justify-center" style="margin-top: -28px;">
                                    <div class="col-12" style="margin-left: 35px;">
                                        <div class="btn-Confirm" @click="closeComfirmAction"
                                            style="width: 90% !important;">
                                            Confirm
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <v-spacer></v-spacer>
                        </v-card>
                    </v-dialog>
                </v-row>
                <!-- hide Mobile screen -->
                <v-row v-else justify="center">
                    <v-dialog v-model="dialogBook" persistent max-width="900px"
                        style="z-index: 1002; background-color: #D9D9D9 !important; border-radius: 10px !important;">
                        <v-card style="background-color: #D9D9D9 !important; border-radius: 10px !important;">
                            <div class="container">
                                <div class="row familyfont" style="padding: 20px 70px">
                                    <div class="col-lg-7 col-md-7 col-sm-7">
                                        <div>
                                            <h1 class="titlebox">សូមអរគុណ</h1>
                                        </div>
                                        <div>
                                            <p class="titledescription">សូមមេត្ដារង់ចាំ
                                                ក្រុមហ៊ុនយើងខ្ញុំនិងធ្វើការទំនាក់ទំនង
                                                ដើម្បីបញ្ជាក់ការជួលរថយន្ដ</p>
                                        </div>
                                        <div>
                                            <p class="titledescription" style="font-size: 16px !important;">
                                                លេខទំនាក់ទំនង៖ 010 522 522 |
                                                081 911 911</p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ឈ្មោះ : {{ fullName }}
                            </pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">លេខទូរស័ព្ទ : {{ phone }}
                            </pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ចេញពីទិសដៅ : {{
                                departureFrom }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ទៅកាន់ទិសដៅ : {{
                                destinationTo }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃចេញដំណើរ : {{ dateFrom
                                }}</pre>
                                            </p>
                                        </div>
                                        <!-- <div style="margin-top: -10px;" v-if="!onWayCheck">
                                            <p style="font-size: 15px !important;"><pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃត្រលបមកវិញ                   :  {{ dateTo }}</pre> </p>
                                        </div> -->
                                        <div style="margin-top: -10px;" v-if="TripCheck">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ថ្ងៃត្រលបមកវិញ : {{ dateTo
                                }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ប្រភេទរថយន្ដ : {{ nameRen
                                }}</pre>
                                            </p>
                                        </div>
                                        <div style="margin-top: -10px;">
                                            <p style="font-size: 15px !important;">
                                            <pre class="titledescription" style="font-size: 15px !important;">ចំនួនរថយន្ដ : {{ amount }}
                            </pre>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="col-lg-5 col-md-5 col-sm-5 d-flex justify-center">
                                        <div>

                                            <div style="text-align: center; margin-left: 20px;margin-bottom: 14px;">
                                                <img src="@/assets/alert/ic_tick_green.svg" alt="">
                                            </div>
                                            <div class="btn-Confirm" @click="closeComfirmAction">
                                                Confirm
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <v-spacer></v-spacer>
                        </v-card>
                    </v-dialog>
                </v-row>
            </section>

        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { VueperSlides, VueperSlide } from 'vueperslides';
import 'vueperslides/dist/vueperslides.css';
export default {
    components: { VueperSlides, VueperSlide },
    data() {
        return {
            radioGroup: 1,
            emptyData: false,
            fullName: "",
            phone: [],
            phoneNumberError: [],
            maxLength: 10, // maximum allowed phone number length
            amount: '',
            note: '',
            proviceCity: [],
            departureFrom: "",
            departureFromid: "",
            destinationTo: "",
            destinationToId: "",
            dialogBook: false,
            onWayCheck: false,
            TripCheck: false,

            showListData: false,
            showListDataTo: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dateFrom: '',
            dateTo: localStorage.getItem('dateTo'),
            menu: false,
            menu2: false,
            clearDate: false,
            nameRen: this.$route.query.name,
            slides: this.$route.query.slides,
            slidesImg: []
        }
    },
    computed: {
        shouldHideDialog() {
            // console.log(this.$vuetify.breakpoint.xsOnly)
            return this.$vuetify.breakpoint.xsOnly;
        },
    },
    watch: {
        phone(value) {
            this.phone = value;
            this.validatePhoneNumber(value);
        },
    },
    methods: {
        selectDeparture() {
            this.departureFrom = '';
            this.departureFromid = '';
            this.showListData = true;
            this.showListDataTo = false;
        },
        filterNumeric() {
            this.amount = this.amount.replace(/\D/g, '');
        },
        // box search
        showPopup() {
            this.showListData = true;
        },
        hidePopup() {
            this.showListData = false;
        },
        showPopupTo() {
            this.showListDataTo = true;
        },
        hidePopupTo() {
            this.showListDataTo = false;
        },
        checkjourneyType() {
            // alert(1);
            if (this.radioGroup == 1) {
                this.onWayCheck = true;
                this.TripCheck = false;
                this.dateTo = localStorage.removeItem("dateTo");
                this.dateFrom = "";
            } else {
                this.TripCheck = true;
                this.dateFrom = "";
                // this.dateFrom=localStorage.getItem("dateFrom");
                this.onWayCheck = false;
            }
        },

        selectDestination() {
            this.destinationTo = '';
            this.destinationToId = '';
            this.showListData = true;
            this.showListDataTo = false;
            this.getDataVehicleRentalProvince();
        },
        selectDestinationTo() {
            this.showListDataTo = true;
            this.showListData = false;
            this.getDataVehicleRentalProvince();
        },
        onConfirmFrom(departureFromName, departureFromId) {
            this.departureFrom = departureFromName;
            this.departureFromid = departureFromId;
            this.showListData = false;
        },
        onConfirmTo(destinationToName, destinationToId) {
            this.destinationTo = destinationToName;
            this.destinationToId = destinationToId;
            this.showListDataTo = false;
        },

        getFromDate() {
            return new Date().toISOString().substr(0, 10);
        },
        storeDate(dateFrom) {
            localStorage.setItem('dateFrom', dateFrom);
            this.dateTo = '';
            localStorage.removeItem('dateTo');
            this.clearDate = false;
        },
        storeDateTo(dateTo) {
            localStorage.setItem('dateTo', dateTo);
            this.clearDate = true;
        },
        getToDate() {
            const today = new Date().toISOString().substr(0, 10);
            this.dateTo = today;
        },
        clearDates() {
            this.dateTo = '';
            localStorage.removeItem('dateTo');
            this.clearDate = false;
        },
        showDataFromRetal() {
            this.nameRen = this.$route.query.name;
            this.slides = this.$route.query.slides;
        },
        async getDataVehicleRentalProvince() {
            const url = this.$url + `vehicle-rental/province`;
            let config = {
                method: "POST",
                headers: this.$header,
                url,
            };
            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        if (response.data.body.data == 0) {
                            this.emptyData = true;
                        } else {
                            this.emptyData = false;
                        }
                        this.proviceCity = response.data.body.data;
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        closeComfirmAction() {
            this.dialogBook = false;
            this.$router.back();
        },
        async saveBook() {

            //this.dialogBook = true;
            // console.log(localStorage.getItem('dateTo'));
            // console.log(this.dateFrom);
            // console.log(this.dateTo);
            // console.log(this.radioGroup);
            if (this.fullName == "" && this.phone == "" && this.departureFromid == "" && this.destinationToId == "" && this.amount == "") {
                alert("Please Input Information");
            } else if ((this.radioGroup == 2 || this.radioGroup == 3) && (this.dateFrom == "" || this.dateFrom == null)) {
                alert("Please Select Departure Date");
            } else if ((this.dateTo == "" || this.dateTo == null) && (this.radioGroup == 2 || this.radioGroup == 3)) {
                alert("Please Select Return Date");
            } else if (this.radioGroup == 1 && (this.dateFrom == "" || this.dateFrom == null)) {
                alert("Please Select Departure Date");
            } else {
                // console.log(this.dateFrom);
                const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/;
                if (regex.test(this.phone)) {
                    const data = {
                        'busTypeId': parseInt(this.$route.query.id),
                        'dateFrom': localStorage.getItem('dateFrom'),
                        'dateTo': localStorage.getItem('dateTo'),
                        'name': this.fullName,
                        'note': this.note,
                        'numberBus': parseInt(this.amount),
                        'provinceFrom': parseInt(this.departureFromid),
                        'provinceTo': parseInt(this.destinationToId),
                        'telephone': this.phone,
                        'travelType': parseInt(this.radioGroup),
                    }

                    // const data = new FormData();
                    // data.append('busTypeId', parseInt(this.$route.params.id));
                    // data.append('dateFrom', localStorage.getItem('dateFrom'));
                    // data.append('dateTo', localStorage.getItem('dateTo'));
                    // data.append('name', this.fullName);
                    // data.append('note', this.note);
                    // data.append('numberBus', parseInt(this.amount));
                    // data.append('provinceFrom', parseInt(this.departureFromid));
                    // data.append('provinceTo', parseInt(this.destinationToId));
                    // data.append('telephone', this.phone);
                    // data.append('travelType', parseInt(this.radioGroup));

                    const url = this.$url + `vehicle-rental/add`;
                    let config = {
                        method: "POST",
                        headers: {
                            ...this.$header,
                            "Content-Type": "multipart/form-data"
                        },
                        // headers: {
                        //     // "Authorization": "Bearer d5cfe6a0-48f3-4c20-b1dd-53a395e064c5",//production
                        //     "Authorization": "Bearer d215ff36-cd41-44d2-8043-db8ffb89005d",//qa
                        //     "Content-Type": "multipart/form-data"
                        // },
                        data: data,
                        url,
                    };
                    axios(config).then((response) => {
                        if (response.status == 200) {
                            if (response.data.header.result == true && response.data.header.statusCode == 200) {
                                this.proviceCity = response.data.body;
                                if (response.data.body.message == "Success") {
                                    // alert("Data has been save!");
                                    this.dialogBook = true;
                                    // this.$router.back();
                                }
                            } else {
                                alert("Please Input Information");
                            }
                        }
                    }).catch((error) => {
                        console.log(error);
                    });
                } else {
                    this.phoneNumberError['phone'] = 'Invalid phone number';
                    alert("Invalid phone number");
                }
            }
        },
        validatePhoneNumber(value) {
            const regex = /^[+]?\d{1,3}[-.\s]?\d{1,14}$/; // regular expression to validate phone number
            if (regex.test(value)) {
                if (value.length <= this.maxLength) {
                    this.phoneNumberError['phone'] = ''; // valid phone number within length limit
                } else {
                    this.phoneNumberError['phone'] = 'Invalid phone number'; // invalid phone number exceeding length limit
                }
            } else {
                this.phoneNumberError['phone'] = 'Invalid phone number'; // invalid phone number
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },
    mounted() {
        this.slidesImg = JSON.parse(this.slides);
    },
    created() {
        this.scrollToTop();
        this.showDataFromRetal();
        // refresh page or first click page
        // this.dateFrom = this.getFromDate();
        localStorage.setItem('dateFrom', this.getFromDate());
        this.dateTo = '';
        localStorage.removeItem('dateTo');

        // refresh page or first click page

        // Set Reload All Page
        localStorage.setItem('reloadHome', "false");
        localStorage.setItem('reloadCompanyProfile', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy', 'false');
    },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Battambang:wght@700;900&display=swap');

/* @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Khmer:wght@100&display=swap'); */
.top-below {
    margin-top: 110px;
    margin-bottom: 50px;
}

.title-booking {
    font-weight: 600;
    font-size: 20px;
    color: #1D1D1D;
    margin-bottom: 20px;
}

.form-input label {
    font-weight: 400;
    font-size: 14px;
    color: #6E6E73;
}

.paddingLeftHeader {
    padding-left: 50px;
}

.card-from {
    padding-bottom: 40px;
}

.journey-type .title-juurney .title {
    font-weight: 600;
    font-size: 16px !important;
    color: #1D1D1D;
}

.theme--light .v-icon {
    color: #22c217 !important;
}

.custom_select {
    padding: 4px 10px 2px;
    outline: none;
    border-radius: 4px;
    height: 40px;
    width: 100%;
    border: 1px solid #c6c6c6;
    background: #ffffff;
    color: #1d1d1d;
    font-weight: 500;
}

.custom_select:hover {
    cursor: pointer;
}

.vueperslide__title {
    font-size: 36px;
    color: #fff;
    font-weight: 600;
    position: absolute;
    bottom: 15%;
}

.list-data {
    padding-top: 10px;
    max-width: 100%;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
}

.icon-date {
    cursor: pointer;
}

.v-list-item--dense .v-list-item__content,
.v-list--dense .v-list-item .v-list-item__content {
    padding: 5px !important;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
    min-height: 20px !important;
}

.v-dialog>.v-card>.v-card__title {
    justify-content: center;
}

.v-dialog>.v-card>.v-card__text {
    padding: 0 24px 11px !important;
    justify-content: center;
    align-items: center;
}

.v-progress-circular {
    margin: 1rem;
}

.loading {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
}

/* .v-dialog{
        background: #D9D9D9 !important;
        border-radius: 10px !important;
    } */
.v-icon.v-icon {
    color: #000000 !important;
}

.v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.reposo-height {
    position: relative;
}

.edit-list:hover {
    background: #1867c0;
    color: #FFF;
    cursor: pointer;
    border-radius: 2px;
}

.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
    align-items: center;
    display: inline-flex;
    flex: 1 1 auto;
    height: auto;
    color: #312783;
}

.car-vip {
    padding-bottom: 10px;
}

.car-vip span {
    font-weight: 400;
    font-size: 14px;
}

.button {
    margin-left: -28px;
    color: #B7B5B5;
}

.silde-car {
    position: relative;
    width: 100%;
    height: 360px;
    border-radius: 12px;
}

.save-submit {
    cursor: pointer;
    margin-top: 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    border: 1px solid #DE5D0A;
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-radius: 8px;
    text-align: center;
    background: #DE5D0A;
}

.familyfont {
    font-family: 'Battambang';
}

.btn-Confirm {
    cursor: pointer;
    padding: 12px 20px;
    text-align: center;
    font-weight: 600;
    font-size: 16px;
    background: #DE5D0A;
    color: #fff;
    border-radius: 8px;
    width: 120%;
}

.titlebox {
    font-family: 'Battambang';
    color: #1D1D1D;
    font-weight: bold;
    font-size: 30px;
}

.titledescription {
    font-family: 'Noto Sans Khmer';
    font-size: 17px;
}

@media (width < 457px) {
    .paddingLeftHeader {
        padding-left: 18px;
    }

    .dialogScreen {
        display: none !important;
    }

    .iphoneScreen1 {
        padding-left: 8px !important;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media(width < 435px) {
    .paddingLeftHeader {
        padding-left: 18px;
    }

    .iphoneScreen1 {
        padding-left: 8px !important;
    }

    .dialogScreen {
        display: none !important;
    }

    /* smartphones, iPhone, portrait 480x320 phones */
}

@media (width < 450px) {

    /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */
    .paddingLeftHeader {
        padding-left: 18px;

    }

    .iphoneScreen1 {
        padding-left: 8px !important;
    }

    .dialogScreen {
        display: none !important;
    }
}
</style>