<template>
    <div class="loginscreen">
        <div class="container-fluid login-container">
            <div class="containers">
                <div class="row header-login">
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">

                    </div>
                    <div class="col-lg-6 col-sm-10 col-md-8 col-xs-12 login-form-container">
                        <form>
                            <h2 class="text-center acount-header">{{ $t('message.loginAccount') }}</h2>
                            <p class="login-paragraph text-center">{{ $t('message.pleaseEnterYourCredentialToLogin') }}
                            </p>
                            <div style="display: flex; flex-direction: column; align-items: center;">
                                <div class="mb-4 mt-2 emailmargin">
                                    <label class="inner-block">{{ $t('message.emailTelephoneNumber') }}<span
                                            style="color: #FF0000; padding-left: 2px;"> *</span></label>
                                    <input type="text" v-model="userForm.email_or_phone"
                                        :class="{ 'is-invalid': isLogin && $v.userForm.email_or_phone.$error }"
                                        class="form-control form-control-lg form-input-login"
                                        :placeholder="$t('message.emailTelephoneNumber')" />
                                    <div v-if="isLogin && !$v.userForm.email_or_phone.required"
                                        class="invalid-feedback">
                                        {{ $t('message.yourEmailTelephoneNumberRequired') }}</div>
                                </div>
                                <div class="mb-3 mt-2 emailmargin">
                                    <label class="inner-block">{{ $t('message.enterPassword') }}<span
                                            style="color: #FF0000; padding-left: 2px;">
                                            *</span></label>
                                    <div class="password-input-container">
                                        <input :type="showPassword ? 'text' : 'password'" v-model="userForm.password"
                                            autocomplete="off"
                                            :class="{ 'is-invalid': isLogin && $v.userForm.password.$error }"
                                            class="form-control form-control-lg form-input-login"
                                            :placeholder="$t('message.enterPassword')" />
                                        <i class="password-toggle-icon"
                                            :class="showPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
                                            @click="togglePasswordVisibility"></i>
                                    </div>

                                    <div v-if="isLogin && !$v.userForm.password.required" class="invalid-feedback">
                                        {{ $t('message.yourPasswordRequired') }}</div>
                                </div>
                                <div class="mb-4 mt-2 emailmargin forgetPasswordform">
                                    <label class="inner-block"><router-link to="/forgot-password"><span
                                                style="color: #FF0000; font-weight: 600 !important;">{{
                                                    $t('message.forgotPassword') }}</span></router-link></label>

                                </div>
                                <div class="mb-2 mt-2 emailmargin">
                                    <v-btn :disabled="disabled" @click="addLogin"
                                        style="border-radius: 4px; width: 91%; outline: none;" color="#DE5D0A"
                                        height="50px" class="text-capitalize btn-width reposo-height">
                                        <span v-if="!disabled" style="color: #FFFF; font-size: 22px !important;">
                                            {{ $t(titleLogin) }}
                                        </span>
                                        <div v-else class="loader-container">
                                            <div class="loader"></div>
                                        </div>
                                    </v-btn>
                                </div>
                                <div class="mb-6 mt-2 emailmargin">
                                    <p class="login-paragraph text-center" style="font-size: 16px;">
                                        {{ $t('message.donotHaveAccountRegister') }}<router-link to="/register"> <span
                                                style="color: #DE5D0A;">{{ $t('message.register') }}</span>
                                        </router-link>
                                    </p>

                                </div>
                            </div>
                        </form>
                    </div>
                    <div class="col-lg-3 col-md-2 col-sm-1 mobilescreencol">

                    </div>

                </div>
            </div>
        </div>

    </div>
</template>
<script>
import {
    required
} from "vuelidate/lib/validators";
import axios from "axios";
export default {
    data() {
        return {
            userForm: {
                email_or_phone: "",
                password: "",
            },
            isLogin: false,
            disabled: false,
            titleLogin: 'message.login',
            showPassword: false,
        }
    },
    validations: {
        userForm: {
            email_or_phone: { required },
            password: { required },
        },
    },
    methods: {
        addLogin() {
            this.isLogin = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            } else {
                this.disabled = true;
                this.titleLogin = "Loading";
                const data = {
                    'username': this.userForm.email_or_phone,
                    'password': this.userForm.password,
                }
                const url = this.$url + `user-register/login`;
                let config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        this.disabled = false;
                        this.titleLogin = 'message.login';
                        if (response.data.header.result == true && response.data.header.statusCode == 200) {
                            if (response.data.body.status == true) {
                                localStorage.setItem('session_start_login', response.data.body.message);

                                // Retrieve the stored redirect URL
                                const redirectUrl = localStorage.getItem('redirect_after_login') || '/';
                                // Clear the stored redirect URL to prevent future redirects
                                localStorage.removeItem('redirect_after_login');

                                setTimeout(() => {
                                    // this.$router.push("/");
                                    this.$router.push(redirectUrl);
                                }, 500);
                            } else {
                                alert(response.data.body.message);
                            }
                        } else {
                            alert("Invalid username or password");
                        }
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }

        },
        handleKeydown(event) {
            if (event.key === 'Enter') {
                this.addLogin();
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },
    },
    mounted() {
        document.addEventListener('keydown', this.handleKeydown);
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.handleKeydown);
    },
    created() {
        this.scrollToTop();
        // localStorage.setItem("handleBrowser", 1);
    }
}
</script>
<style scoped>
.loginscreen {
    padding-top: 55px;
    min-height: calc(110vh - 320px);
    display: flex;
    flex-direction: column;
    position: relative;
    background: #f2f2f2;
}

.login-container {
    flex: 1;
    width: 100%;
    padding: 28px 0;
}

.containers {
    height: 100%;
}

.header-login {
    min-height: 100%;
    padding: 35px 0 70px 0;
    margin: 0;
}

.login-form-container {
    background: #FFFFFF;
    border-radius: 15px;
    padding: 20px 0;
    height: fit-content;
}

.invalid-feedback {
    color: #FF0000;
}

.is-invalid {
    border: 1px solid #FF0000 !important;
}

.acount-header {
    margin-top: 15px;

}

.emailmargin {
    margin-left: 40px;
    width: 91%;
}

.login-paragraph {
    color: #6A6969;
    font-size: 13px;
}

.login-paragraph span {
    padding-left: 6px;
}

label {
    font-weight: bold;
}

.inner-block {
    display: block;
    width: 92%;
    margin-bottom: 4px;
}

.form-input-login {
    /* width: 100%; */
    width: 91%;
    border: 1px solid #C6C6C6;
    border-radius: 6px;
    height: 45px;
    outline: none;
    padding-left: 12px;
}

.forgetPasswordform {
    width: 91%;
    text-align: right;
}

.btn-width {
    width: 91%;
    border-radius: 4px;
    background-color: #DE5D0A;
    position: relative;
    overflow: hidden;
}

@media(min-width: 1740px) {}

@media(width < 1024px) {}

@media screen and (width < 950px) {
    /* .loginscreen {
            margin-top: -20px;
        } */
    /* .mobilescreencol{
            display: none !important;
        } */


}

@media screen and (width < 620px) {

    /* .loginscreen {
            margin-top: -20px;
        } */
    /* .mobilescreencol{
            display: none !important;
        } */
    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        /* margin-left: 5px; */

    }

    .form-input-login,
    .inner-block,
    .btn-width {
        /* width: 97% !important; */
    }

    .forgetPasswordform {
        /* width: 99%; */
    }

    .loader {
        width: 24px;
    }
}

@media screen and (width < 500px) {

    /* .loginscreen {
            margin-top: -20px;
        } */
    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        /* margin-left: 5px; */

    }

    .form-input-login,
    .inner-block,
    .btn-width {
        /* width: 97% !important; */
    }

    .forgetPasswordform {
        /* width: 99%; */
    }
}

@media(width < 450px) {

    /* .loginscreen {
            margin-top: -20px;
        } */
    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        /* margin-left: 5px; */

    }

    .form-input-login,
    .inner-block,
    .btn-width {
        /* width: 97% !important; */
    }

    .forgetPasswordform {
        /* width: 99%; */
    }

}

@media screen and (width < 438px) {
    /* .loginscreen {
            margin-top: -20px;
        } */


    .mobilescreencol {
        display: none !important;
    }

    .header-login {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .acount-header {
        margin-top: 10px;
    }

    .emailmargin {
        /* margin-left: 5px; */

    }

    .form-input-login,
    .inner-block,
    .btn-width {
        /* width: 97% !important; */
    }

    .forgetPasswordform {
        /* width: 99%; */
    }
}

.password-input-container {
    position: relative;
    /* width: 91%; */
}

.password-toggle-icon {
    position: absolute;
    right: 12%;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #6A6969;
}

@media screen and (width < 620px) {
    .password-input-container {}
}

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.loader {
    width: 120px;
    height: 4px;
    background: linear-gradient(#2c2a2a 0 0) 0/0% no-repeat rgba(255, 255, 255, 0.2);
    animation: loading 2s infinite linear;
}

@keyframes loading {
    100% {
        background-size: 100%;
    }
}
</style>