<template>

    <div class="register">
        <div>
            <HeaderComponent />
            <RegisterComponent />
        </div>
    </div>

</template>

<script>
import HeaderComponent from '@/components/Headers/HeaderComponent.vue';
import RegisterComponent from '@/components/users/RegisterComponent.vue';
export default {
    components: {
        HeaderComponent,  // HeaderComponent is defined in HeaderComponent.vue
        RegisterComponent,
    },
    data() {
        return {
            // loading: true,
        }
    },
    method: {

    },
    created() {
        // this.loading = false
    }
}
</script>
<style scoped></style>