<template>
  <div>
    <div class="vet-value">
      <div class="containers mt-4">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 d-flex-center">
            <div>
              <div class="core-values">
                {{ $t('message.coreValues') }}
                <img src="@/assets/arrow-core.svg" style="margin-top: 20px; width: 65px; margin-left: 7px" alt="" />
              </div>
              <p class="add-font">{{ $t('message.desVetCorporationCoreValues') }}</p>
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12">
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6 pl-5 pr-5">
                <div class="bg-fe7a65">
                  <div style="text-align: center">
                    <img src="@/assets/relationship.svg" alt="" />
                  </div>
                  <p>
                    <span>{{ $t('message.vetCorporationCoreValuesConnected') }}</span>
                    {{ $t('message.vetCorporationCoreValuesConnectedP') }}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6 pl-5 pr-5">
                <div class="bg-4587e5">
                  <div style="text-align: center">
                    <img src="@/assets/trusted.svg" alt="" />
                  </div>
                  <p>
                    <span>{{ $t('message.vetCorporationCoreValuesCommitted') }}</span>
                    {{ $t('message.vetCorporationCoreValuesCommittedP') }}
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6 pl-5 pr-5">
                <div class="bg-ffbb34">
                  <div style="text-align: center">
                    <img src="@/assets/recommended.svg" alt="" />
                  </div>
                  <p>
                    <span>{{ $t('message.vetCorporationCoreValuesCreative') }}</span>
                    {{ $t('message.vetCorporationCoreValuesCreativeP') }}
                  </p>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 col-sm-6 col-xs-6 pl-5 pr-5">
                <div class="background">
                  <div style="text-align: center">
                    <img src="@/assets/on-time.svg" alt="helle" />
                  </div>
                  <p>
                    <span>{{ $t('message.vetCorporationCoreValuesTrust') }}</span>
                    {{ $t('message.vetCorporationCoreValuesTrustP') }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.scrollToTop();
  }
};
</script>

<style scoped>
.vet-value {
  padding-top: 120px;
  padding-bottom: 50px;
}

.d-flex-center {
  display: flex;
  align-items: center;
}

.core-values {
  width: 100%;
  display: flex;
  font-size: 36px;
  font-weight: 600;
}

.bg-fe7a65 {
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background: #fe7a65;
  height: 300px;
  border-radius: 20px;
  color: #fff;
}

.background {
  padding-top: 13px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background: #fe7a65;
  height: 300px;
  border-radius: 20px;
  color: #fff;
}

.bg-fe7a65 p {
  font-size: 13px;
}

.bg-fe7a65 span {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}

.bg-4587e5 p {
  font-size: 13px;
}

.bg-4587e5 span {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}

.bg-4587e5 {
  color: #fff;
  padding-top: 13px;
  background: #56d7ff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 300px;
  border-radius: 20px;
}

.bg-ffbb34 p {
  font-size: 13px;
}

.bg-ffbb34 span {
  font-weight: 600;
  font-size: 13px;
  color: #fff;
}

.bg-ffbb34 {
  color: #fff;
  padding-top: 13px;
  background: #ffbb34;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  height: 300px;
  border-radius: 20px;
}

@media (width < 500px) {
  .vet-value {
    padding-top: 100px;
  }
}

@media (width < 450px) {
  .add-font {
    font-size: 14px;
  }

  .bg-fe7a65 span,
  p {
    font-size: 14px;
  }

  .bg-ffbb34 span,
  p {
    font-size: 14px;
  }

  .background span,
  p {
    font-size: 14px;
  }
}
</style>