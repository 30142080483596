<template>
  <div class="blog">
    <div class="containers">

      <p class="title-blogs-cambodia">{{ $t('message.JourneyGuideInCambodia') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card" style="padding: 0px 12px;">
          <div class="blog-img">
            <img @click="blog1"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt="" />
          </div>
          <div class="groupText" @click="blog1">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">
              {{ $t('message.crossBorderInformationOrocedure') }}</p>
          </div>
        </div>
        <!-- <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog2"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog2">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{$t('message.siemReaptitle1')}}</p>
          </div>
        </div> -->
        <!-- <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog3"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog3">
            <p style="display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; overflow: hidden;">{{$t('message.battambangTitle1')}}</p>
          </div>
        </div> -->
        <!-- <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog4"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog4">
            <p>{{$t('message.sihanoukvilleTitle1')}}</p>
          </div>
        </div> -->
        <!-- <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog5"
              src="@/assets/carousel/advs-new-route.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog5">
            <p>{{$t('message.KratieTitle1')}}</p>
          </div>
        </div> -->
      </div>
      <!-- <p class="title-blogs-laos">{{ $t('message.JourneyGuideInLaos') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog6"
              src="https://kalelogistics.com/usa/wp-content/uploads/2023/11/MicrosoftTeams-image-20-scaled.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog6">
            <p>{{$t('message.laos1')}}</p>
          </div>
        </div>
      </div> -->
      <!-- <p class="title-blogs-laos">{{ $t('message.Lifestyle') }}</p>
      <div class="row">
        <div class="col-lg-4 col-md-6 col-sm-12 card">
          <div class="blog-img">
            <img
              @click="blog7"
              src="@/assets/carousel/ads.jpg"
              alt=""
            />
          </div>
          <div class="groupText" @click="blog7">
            <p>{{$t('message.OnlineShopping')}}</p>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    navigateToBlog(blogId) {
      if (this.$route.path !== "/new-blog") {
        localStorage.setItem("blog-img", blogId);
        this.$router.push("/new-blog").catch(err => {
          if (err.name !== 'NavigationDuplicated') {
            throw err;
          }
        });
      }
    },
    blog1() {
      this.navigateToBlog(1);
    },
    blog2() {
      this.navigateToBlog(2);
    },
    blog3() {
      this.navigateToBlog(3);
    },
    blog4() {
      this.navigateToBlog(4);
    },
    blog5() {
      this.navigateToBlog(5);
    },
    blog6() {
      this.navigateToBlog(6);
    },
    blog7() {
      this.navigateToBlog(7);
    }
  },
  created() { },
};
</script>

<style scoped>
/* .blog {
  height: 90vh;
} */
.title-blogs-cambodia {
  padding-top: 100px;
  padding-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
}
 p{
  margin-bottom: 0px;
}

.title-blogs-laos {
  font-size: 30px;
  font-weight: 600;
}

.card {
  /* margin-top: 20px; */
  cursor: pointer;
}

.blog-img {
  width: 100%;
  height: 240px;
}

.blog-img img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.img-blog {
  cursor: pointer;
  width: 100%;
  height: 220px;
  border-radius: 12px;
  overflow: hidden;
}

.img-blog img {
  width: 100%;
  height: 100%;
}

.groupText {
  padding-top: 8px;
}

.groupText p:hover {
  color: #de5d0a;
}

@media (min-width: 1740px) {
  .set-heights {
    height: 500px;
  }
}

.no-result {
  margin-top: 15px;
  font-size: 25px;
  font-weight: 600;
}

@media (width < 1024px) {
  .set-heights {
    height: 500px;
  }
}

@media (width < 450px) {
  .blog {
    /* height: 70vh; */
    padding: 50px 0;
  }
  .title-blogs-cambodia {
  font-size: 18px;
  font-weight: 600;
}

  .row {
    /* margin-top: 20px; */
  }

  .no-result {
    font-size: 16px;
  }

  img {
    width: 86px;
  }

  .blog-img {
    height: 220px;
    border-radius: 6px;
  }

  .groupText p {
    font-size: 15px;
  }
}

/* Default styles (for screens 3000px and above) */
.title-blogs-cambodia {
    padding-top: 100px;
    padding-bottom: 20px;
    font-size: 30px;
    font-weight: 600;
}

/* Large desktop screens */
@media screen and (max-width: 1920px) {
    .title-blogs-cambodia {
        padding-top: 100px;
        font-size: 28px;
    }
}

/* Standard desktop screens */
@media screen and (max-width: 1440px) {
    .title-blogs-cambodia {
        padding-top: 100px;
        font-size: 26px;
    }
}

/* Small desktop/laptop screens */
@media screen and (max-width: 1024px) {
    .title-blogs-cambodia {
        padding-top: 100px;
        font-size: 24px;
    }
}

/* Tablets */
@media screen and (max-width: 768px) {
    .title-blogs-cambodia {
        padding-top: 90px;
        font-size: 22px;
    }
}

/* Large mobile devices */
@media screen and (max-width: 576px) {
    .title-blogs-cambodia {
        padding-top: 80px;
        font-size: 20px;
    }
}

/* Small mobile devices */
@media screen and (max-width: 450px) {
    .title-blogs-cambodia {
        padding-top: 30px;
        font-size: 18px;
    }
}

/* Extra small mobile devices */
@media screen and (max-width: 385px) {
    .title-blogs-cambodia {
        padding-top: 30px;
        font-size: 16px;
    }
}
</style>