<template>
    <header :class="{ 'onScroll': !narbarTop.topOfPage, 'header': narbarTop.topOfPage }">
        <div class="nav containers" style="padding: 10px 0">
            <h2 class="nav_logo">
                <router-link to="/" style="border-bottom: none !important" @click.native="scrollUp">
                    <img src="@/assets/vireak-buntham.png" alt="Logo" height="42px">
                </router-link>
            </h2>
            <ul class="menu_items" :class="isMobileNavigationVisible === false ? 'menu_items' : 'menu_itemes'">
                <img class="hidden" src="@/assets/vireak-buntham.png" alt="" width="50">
                <i class="fa-solid fa-xmark" id="menu_toggle" @click="nav_toggle"></i>
                <!-- small screen start -->
                <li class="pad-top home mobileScreenli" @click="handleHomeNavigation">
                    <span class="nav_link" @click.native.prevent>{{ $t('message.home') }}</span>
                </li>
                <!-- small screen end -->

                <li class="pad-top" 
                    @click="handleNavigation('/about-us')"
                    :class="{ 'active-nav': isCurrentRoute('/about-us') }">
                    <span class="nav_link">{{ $t('message.aboutUs') }}</span>
                </li>
                <li class="pad-top" 
                    @click="handleNavigation('/travel-package')"
                    :class="{ 'active-nav': isCurrentRoute('/travel-package') }">
                    <span class="nav_link">{{ $t('message.travelPackage') }}</span>
                </li>
                <li class="pad-top" 
                    @click="handleNavigation('/vehicle-rental')"
                    :class="{ 'active-nav': isCurrentRoute('/vehicle-rental') }">
                    <span class="nav_link">{{ $t('message.vehicleRental') }}</span>
                </li>
                <li class="pad-top" 
                    @click="handleNavigation('/galleries')"
                    :class="{ 'active-nav': isCurrentRoute('/galleries') }">
                    <span class="nav_link">{{ $t('message.gallery') }}</span>
                </li>
                <li class="pad-top" 
                    @click="handleNavigation('/blog')"
                    :class="{ 'active-nav': isCurrentRoute('/blog') }">
                    <span class="nav_link">{{ $t('message.blogs') }}</span>
                </li>
                <li class="pad-top contact-menu" @click="scrollDown()">
                    <span id="contact" class="nav_link">{{ $t('message.contact') }}</span>
                </li>
                <!-- subsidairy small screen -->
                <li class="pad-top mobileScreenli" style="cursor: pointer;">
                    <div class="smallOnlymobile"
                        style="display: flex; justify-content: space-between; align-items: center;"
                        @click="toggleSubsidiaryPopup($event)">
                        <div>
                            <span class="nav_link">{{ $t('message.subsidiary') }}</span>
                        </div>
                        <div>
                            <img src="@/assets/arrows/vector-bottom.svg" alt="">
                        </div>
                    </div>
                    <transition name="slide">
                        <div class="useraccount" v-if="showSubsidiaryPopup">
                            <ul class="mobileul">
                                <li @click="goToSubsidiary('buva-sea')" 
                                    class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;">
                                    <div class="d-flex align-center" style="display: flex; justify-content: flex-start; align-items: center;">
                                        <div>
                                            <img src="@/assets/company_subsidiary/buva.svg" alt="Buva Sea" width="32">
                                        </div>
                                        <router-link to="#" style="padding-left: 12px;" class="nav_link_sub">
                                            {{ $t('message.buvaseacambodai') }}
                                        </router-link>
                                    </div>
                                </li>
                                <li @click="goToSubsidiary('vet-airbus')" 
                                    class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;">
                                    <div class="d-flex align-center" style="display: flex; justify-content: flex-start; align-items: center;">
                                        <div><img src="@/assets/company_subsidiary/vet_air.svg" alt="VET AIRBUS" width="32"></div>
                                        <router-link to="#" style="padding-left: 12px;" class="nav_link_sub">
                                            {{ $t('message.airbus') }}
                                        </router-link>
                                    </div>
                                </li>
                                <li @click="goToSubsidiary('/accommodation')" 
                                    class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;">
                                    <div class="d-flex align-center" style="display: flex; justify-content: flex-start; align-items: center;">
                                        <div><img src="@/assets/company_subsidiary/resort.svg" alt="Accommodation" width="32"></div>
                                        <router-link to="/accommodation" style="padding-left: 12px;" class="nav_link_sub">
                                            {{ $t('message.accommodation') }}
                                        </router-link>
                                    </div>
                                </li>
                                <li @click="goToSubsidiary('vtenh')" 
                                    class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;">
                                    <div class="" style="display: flex; justify-content: flex-start; align-items: center;">
                                        <div><img src="@/assets/company_subsidiary/vtenh.svg" alt="VTENH" width="32"></div>
                                        <router-link to="#" style="padding-left: 12px;" class="nav_link_sub">
                                            {{ $t('message.vtenh') }}
                                        </router-link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </transition>
                    <div v-if="showSubsidiaryPopup" class="background_popup" @click="closeAllPopups"></div>
                </li>
                <li class="pad-top destopApp" 
                    @click="handleSubsidiaryClick"
                    @mouseenter="showSubsidiaryPopup = true">
                    <span class="nav_link">{{ $t('message.subsidiary') }}</span>
                </li>
                <!-- User account on small screen-->
                <li class="pad-top mobileScreenli" v-if="session_login != null" style="cursor: pointer;">
                    <div class="smallOnlymobile"
                        style="display: flex; justify-content: space-between; align-items: center;"
                        @click="toggleUserPopup($event)">
                        <div>
                            <router-link to="#" class="nav_link">User
                                Account</router-link>
                        </div>
                        <div>
                            <img src="@/assets/arrows/vector-bottom.svg" alt="">
                        </div>
                    </div>
                    <transition name="slide">
                        <div class="useraccount" v-if="showUserPopup">
                            <ul class="mobileul">
                                <li @click="handleNavigation('/profile-account')" class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;" title="Khmer">
                                    <div class="d-flex align-center" style="">
                                        <span class="nav_link">
                                            {{ $t('message.profileAccount') }}
                                        </span>
                                    </div>
                                </li>
                                <li @click="handleNavigation('/history-travel-package')" class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;" title="Khmer">
                                    <div class="d-flex align-center" style="">
                                        <span class="nav_link">
                                            {{ $t('message.travelPackageHistory') }}
                                        </span>
                                    </div>
                                </li>
                                <li @click="handleNavigation('/history-ticket')" class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;" title="Khmer">
                                    <div class="d-flex align-center" style="">
                                        <span class="nav_link">
                                            {{ $t('message.ticketHistory') }}
                                        </span>
                                    </div>
                                </li>
                                <li @click="handleLogout()" class="pad-top d-flex justify-space-between align-center"
                                    style="color: #1D1D1D !important;" title="Khmer">
                                    <div class="d-flex align-center" style="">
                                        <span class="nav_link">
                                            {{ $t('message.logout') }}
                                        </span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </transition>
                </li>
                <li class="pad-top mobileScreenli" v-else @click="handleNavigation('/login')">
                    <span class="nav_link">{{ $t('message.loginRegister') }}</span>
                </li>
                <li class="pad-top destopApp" @click="toggleLanguagePopup($event)" v-click-outside="hidePopup"
                    style="position: relative;" title="Switch Language">
                    <span style="color: #000;font-weight: 500; display: flex; align-items: center;" class="nav_link">
                        <img :src="getCurrentLanguageIcon" alt="Language Icon" width="28px" height="20px"
                            style="margin-right: 8px;">

                        <img src="@/assets/flag/caret-down-outline.svg" alt="" style="margin-top: 3px;">
                    </span>

                    <!-- Language Popup -->
                    <div v-if="showLanguagePopup" class="language-popup">
                        <div title="English" @click="selectLanguage('en')"
                            :class="{ 'color-highlight': this.lange == 'en', 'disabled': this.lange == 'en' }"
                            class="d-flex align-center language-item">
                            <div class="language-icon">
                                <img :src="getLanguageIcon('en')" alt="" width="28px" height="20px">
                            </div>
                            <div class="language-text">
                                <p>English</p>
                            </div>
                        </div>
                        <div title="China" @click="selectLanguage('ch')"
                            :class="{ 'color-highlight': this.lange == 'ch', 'disabled': this.lange == 'ch' }"
                            class="d-flex align-center language-item">
                            <div class="language-icon">
                                <img :src="getLanguageIcon('ch')" alt="" width="28px" height="20px">
                            </div>
                            <div class="language-text">
                                <p>中文</p>
                            </div>
                        </div>
                    </div>
                </li>
                <!-- Language on small screen-->
                <li class="pad-top mobileScreenli" style="cursor: pointer;">
                    <div class="smallOnlymobile"
                        style="display: flex; justify-content: space-between; align-items: center;"
                        @click="toggleMobileLanguagePopup($event)">
                        <div>
                            <span class="nav_link">Language</span>
                        </div>
                        <div>
                            <img src="@/assets/arrows/vector-bottom.svg" alt="">
                        </div>
                    </div>
                    <transition name="slide">
                        <div class="useraccount" v-if="showMobileLanguagePopup">
                            <ul class="mobileul">
                                <li @click="selectLanguage('en')" 
                                    class="pad-top "
                                    style="color: #1D1D1D !important; display: flex; justify-content: space-between; align-items: center;">
                                    <div class="d-flex align-center" style="">
                                        <router-link to="#" class="nav_link_sub">
                                            English
                                        </router-link>
                                    </div>
                                    <div v-if="this.lange == 'en'" style="padding-left: 10px;">
                                        <img src="@/assets/arrows/check.svg" width="15px" height="15px" alt="">
                                    </div>
                                </li>
                                <li @click="selectLanguage('ch')" 
                                    class="pad-top "
                                    style="color: #1D1D1D !important; display: flex; justify-content: space-between; align-items: center;">
                                    <div class="d-flex align-center" style="">
                                        <router-link to="#" class="nav_link_sub">
                                            中文
                                        </router-link>
                                    </div>
                                    <div v-if="this.lange == 'ch'" style="padding-left: 10px;">
                                        <img src="@/assets/arrows/check.svg" width="15px" height="15px" alt="">
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </transition>
                    <div v-if="showMobileLanguagePopup" class="background_popup" @click="closeAllPopups"></div>
                </li>
                
                <li class=" destopApp" v-if="session_login != null" @click="toggleUserPopup($event)"
                    style="position: relative;" title="User Account">

                    <span class="sign-in-acc" style="color: #FFFFFF; font-weight: 500;">
                        {{ nameProfile.length > 3
                            ? nameProfile.charAt(0).toUpperCase() + nameProfile.substring(1, 3) + '..'
                            : nameProfile.charAt(0).toUpperCase() + nameProfile.slice(1) }}
                        <i class="fa-solid fa-chevron-down"></i>
                    </span>

                    <div v-if="showUserPopup" class="user-popup">
                        <router-link to="/profile-account" @click="handleNavigation('/profile-account')">
                            <div class="" style="display: flex; justify-content: space-between; align-items: center;"
                                title="Profile Account">
                                <div>
                                    {{ $t('message.profileAccount') }}
                                </div>
                                <div class="chevron-container">
                                    <img src="@/assets/travel_package/chevron-right.svg" alt="chevron"
                                        class="chevron-icon">
                                </div>

                            </div>
                        </router-link>
                        <router-link to="/history-travel-package" @click="handleNavigation('/history-travel-package')">
                            <div class="" style="display: flex; justify-content: space-between; align-items: center;"
                                title="Travel Package History">
                                <div>
                                    {{ $t('message.travelPackageHistory') }}
                                </div>
                                <div class="chevron-container">
                                    <img src="@/assets/travel_package/chevron-right.svg" alt="chevron"
                                        class="chevron-icon">
                                </div>

                            </div>
                        </router-link>
                        <router-link to="/ticket-history" @click="handleNavigation('/history-ticket')">
                            <div class="" style="display: flex; justify-content: space-between; align-items: center;"
                                title="Ticket History">
                                <div>
                                    {{ $t('message.ticketHistory') }}
                                </div>
                                <div class="chevron-container">
                                    <img src="@/assets/travel_package/chevron-right.svg" alt="chevron"
                                        class="chevron-icon">
                                </div>

                            </div>
                        </router-link>
                        <!-- <router-link to="login"> -->
                        <div class="" style="display: flex; justify-content: space-between; align-items: center;"
                            title="Log Out" @click="handleLogout()">
                            <div>
                                {{ $t('message.logout') }}
                            </div>
                            <div class="chevron-container">
                                <img src="@/assets/travel_package/chevron-right.svg" alt="chevron" class="chevron-icon">
                            </div>

                        </div>
                        <!-- </router-link> -->
                    </div>
                    <div v-if="showUserPopup" class="background_popup" @click="closeAllPopups"></div>
                </li>
                <li class=" destopApp" v-else @click="handleNavigation('/login')">
                    <span style="color: #FFFFFF;font-weight: 500" class="nav_link1 sign-in-acc">Sign
                        in Account</span>
                </li>


            </ul>
            <i class="fa-solid fa-bars" id="menu_toggle" @click="nav_toggle"></i>
        </div>

        <!-- Update subsidiary popup with click-outside directive -->
        <div  v-if="showSubsidiaryPopup" 
             v-click-outside="closeSubsidiaryPopup"
             class="subsidiary-popup destopApp"
             @mouseenter="showSubsidiaryPopup = true"
             @mouseleave="showSubsidiaryPopup = false"
             style="width: 100%; background-color: #FFFFFF;">
          <div class="containers " >
              <div class="row subsidiary-wrapper" style="margin: 0px;">
                  <div class="col-lg-4 col-md-4 col-sm-12 subsidiary-title" style="padding-left: 0px;">
                      <p class="subtitle">{{ $t('message.theSubsidiaryof') }}</p>
                      <h1 class="title">{{$t('Vireak Buntham Group')}}</h1>
                  </div>
                  <div class="col-lg-8 col-md-8 col-sm-12">
                      <div class="subsidiary-grid">
                          <div class="subsidiary-item" @click="goToSubsidiary('buva-sea')">
                              <div class="subsidiary-icon">
                                  <img src="@/assets/company_subsidiary/buva.svg" alt="Buva Sea" width="40">
                              </div>
                              <div class="subsidiary-content">
                                  <h4>{{ $t('message.buvaseacambodai') }}</h4>
                                  <!-- <p>See the schedule and Speet-Boat</p> -->
                              </div>
                          </div>

                          <div class="subsidiary-item" @click="goToSubsidiary('vet-airbus')">
                              <div class="subsidiary-icon">
                                  <img src="@/assets/company_subsidiary/vet_air.svg" alt="VET AIRBUS" width="40">
                              </div>
                              <div class="subsidiary-content">
                                  <h4>{{ $t('message.airbus') }}</h4>
                                  <!-- <p>See the schedule and bus</p> -->
                              </div>
                          </div>

                          <div class="subsidiary-item" @click="goToSubsidiary('/accommodation')">
                              <div class="subsidiary-icon">
                                  <img src="@/assets/company_subsidiary/resort.svg" alt="Accommodation" width="40">
                              </div>
                              <div class="subsidiary-content">
                                  <h4>{{ $t('message.accommodation') }}</h4>
                                  <!-- <p>See the resort</p> -->
                              </div>
                          </div>

                          <div class="subsidiary-item" @click="goToSubsidiary('vtenh')">
                              <div class="subsidiary-icon">
                                  <img src="@/assets/company_subsidiary/vtenh.svg" alt="VTENH" width="40">
                              </div>
                              <div class="subsidiary-content">
                                  <h4>{{ $t('message.vtenh') }}</h4>
                                  <!-- <p>See the e-commerce</p> -->
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </header>
</template>


<script>
import axios from 'axios';
export default {
    data() {
        return {
            isMobileNavigationVisible: false,
            narbarTop: {
                topOfPage: true
            },
            showLanguagePopup: false,
            showUserPopup: false,
            lange: this.$i18n.locale,
            session_login: localStorage.getItem('session_start_login'),
            nameProfile: localStorage.getItem('nameProfile'),
            showMobileLanguagePopup: false,
            showMobileUserPopup: false,
            showSubsidiaryPopup: false,
        }
    },
    methods: {
        allPage() {
            document.getElementById("contact")?.classList.remove("router-link-exact-active");
            this.isMobileNavigationVisible = false;
        },
        toggleLanguagePopup(event) {
            // Prevent event from bubbling up 
            event.stopPropagation();

            // Close all other popups first
            this.closeAllPopups();
            // Then toggle this popup
            this.showLanguagePopup = !this.showLanguagePopup;
        },
        hidePopup() {
            this.showLanguagePopup = false;
        },
        closeLanguagePopup() {
            this.showLanguagePopup = false; // Close the language popup
        },
        removeSessionInfoLogout() {
            localStorage.removeItem('session_start_login');
            localStorage.removeItem('nameProfile');
            localStorage.removeItem('usernameProfile');
            localStorage.removeItem('emailProfile');
            localStorage.removeItem('dobProfile');
            localStorage.removeItem('telephoneProfile');
            localStorage.removeItem('nationalityIdProfile');
            localStorage.removeItem('genderProfile');
            localStorage.removeItem('addressProfile');
            this.$router.push("/login");
        },
        handleLogout() {
            this.allPage();
            this.logoutProfile();
            this.isMobileNavigationVisible = false;
        },
        toggleUserPopup(event) {
            // Prevent event from bubbling up
            event.stopPropagation();

            // Toggle this popup
            this.showUserPopup = !this.showUserPopup;

            // Close other popups
            this.showLanguagePopup = false;
            this.showMobileLanguagePopup = false;
            this.showMobileUserPopup = false;
        },
        handleOutsideClick(event) {
            // If clicking on a popup trigger element, let the toggle handlers deal with it
            const languageTrigger = event.target.closest('[title="Switch Language"]');
            const userTrigger = event.target.closest('[title="User Account"]');
            const subsidiaryTrigger = event.target.closest('.pad-top');

            if (languageTrigger || userTrigger || subsidiaryTrigger) {
                return;
            }

            // If clicking outside popups and their triggers, close all popups
            if (!event.target.closest('.language-popup') &&
                !event.target.closest('.user-popup') &&
                !event.target.closest('.mobile-popup') &&
                !event.target.closest('.subsidiary-popup')) {
                this.closeAllPopups();
            }
        },
        closeUserPopup() {
            this.showUserPopup = false;
        },
        mounted() {
            document.addEventListener('click', this.handleOutsideClick);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleOutsideClick);
        },
        RouterPage(value) {
            if (value == "Profile") {
                this.$nextTick(() => {
                    this.$router.push("/profile-account");
                });
            } else if (value == "Travel") {
                this.$router.push("/company-profile");
            } else if (value == "Ticket") {
                this.$router.push("/rental");
            } else if (value == "Log") {
                this.$router.push("/logout");
            }
        },

        selectLanguage(locale) {
            this.$i18n.locale = locale;
            this.lange = locale;
            this.showLanguagePopup = false;
            localStorage.setItem("lang", this.$i18n.locale);

            if (this.$route.path == "/" || this.$route.path == "/passenger-info" || this.$route.path == "/travel-package" || this.$route.name == "register-buy-package") {
                window.location.reload();
            }

            localStorage.setItem('adspopup', '1');

            this.isMobileNavigationVisible = false;
            this.closeAllPopups();
        },
        nav_toggle() {
            this.isMobileNavigationVisible = !this.isMobileNavigationVisible;
            this.closeAllPopups();
        },
        scrollDown() {
            // Simply scroll to the bottom smoothly
            window.scrollTo({
                top: document.documentElement.scrollHeight,
                behavior: 'smooth'
            });
            // Close mobile menu if open
            this.isMobileNavigationVisible = false;
        },
        scrollUpAndClose() {
            this.scrollUp();
            this.isMobileNavigationVisible = false; // Close the mobile menu
        },
        scrollUp() {
            if (this.$route.path !== "/") {
                this.$router.go();
                this.$router.push("/");
            }
            document.getElementById("contact")?.classList.remove("router-link-exact-active");
            this.isMobileNavigationVisible = false; // Close the mobile menu
        },
        handleScroll() {
            if (window.pageYOffset > 0) {
                if (this.narbarTop.topOfPage) this.narbarTop.topOfPage = false
            } else {
                if (!this.narbarTop.topOfPage) this.narbarTop.topOfPage = true
            }
        },
        async logoutProfile() {
            const url = this.$url + `user-register/logout`;
            const data = {
                'session': localStorage.getItem('session_start_login'),
            }
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {
                        this.removeSessionInfoLogout();
                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        async getMyprofile() {
            const url = this.$url + `user-register/me`;
            const data = {
                'session': localStorage.getItem('session_start_login'),
            }
            let config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data"
                },
                data: data,
                url,
            };

            axios(config).then((response) => {
                if (response.status == 200) {
                    if (response.data.header.result == true && response.data.header.statusCode == 200) {

                        if (localStorage.getItem('nameProfile') == null) {
                            this.nameProfile = response.data.body.name;
                        }
                        localStorage.setItem('nameProfile', response.data.body.name);
                        localStorage.setItem('usernameProfile', response.data.body.username);
                        localStorage.setItem('emailProfile', response.data.body.email);
                        localStorage.setItem('dobProfile', response.data.body.dob);
                        localStorage.setItem('telephoneProfile', response.data.body.telephone);
                        localStorage.setItem('nationalityIdProfile', response.data.body.nationality);
                        localStorage.setItem('genderProfile', response.data.body.gender);
                        localStorage.setItem('addressProfile', response.data.body.address);

                    }
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        getLanguageIcon(lang) {
            switch (lang) {
                case 'en':
                    return require('@/assets/icon_bars/ic_english.png');
                case 'ch':
                    return require('@/assets/icon_bars/ic_chinese.png');
                default:
                    return require('@/assets/icon_bars/ic_english.png');
            }
        },
        toggleMobileLanguagePopup(event) {
            // Prevent event from bubbling up
            event.stopPropagation();

            // Toggle the popup
            if (this.showMobileLanguagePopup) {
                // If open, close it
                this.closeAllPopups();
            } else {
                // If closed, close other popups first then open this one
                this.closeAllPopups();
                this.showMobileLanguagePopup = true;
            }
        },
        toggleMobileUserPopup() {
            this.showMobileUserPopup = !this.showMobileUserPopup;
            this.showMobileLanguagePopup = false;
        },
        closeAllPopups() {
            this.showLanguagePopup = false;
            this.showUserPopup = false;
            this.showMobileLanguagePopup = false;
            this.showMobileUserPopup = false;
            this.showSubsidiaryPopup = false;
        },
        goToSubsidiary(type) {
            this.showSubsidiaryPopup = false;
            this.isMobileNavigationVisible = false;

            switch (type) {
                case 'buva-sea':
                    window.open('https://www.buvasea.com/', '_blank');
                    break;
                case 'vet-airbus':
                    window.open('https://vetairbus.com', '_blank');
                    break;
                case '/accommodation':
                    window.open('/accommodation', '_blank');
                    break;
                case 'vtenh':
                    window.open('https://www.vtenh.com/en/', '_blank');
                    break;
                default:
                    this.$router.push('/subsidiary');
            }
        },
        handleSubsidiaryClick() {
            // Force show popup on click
            this.showSubsidiaryPopup = true;
            
            // Close other popups
            this.showLanguagePopup = false;
            this.showUserPopup = false;
            this.showMobileLanguagePopup = false;
            this.showMobileUserPopup = false;
        },
        closeSubsidiaryPopup() {
            this.showSubsidiaryPopup = false;
        },
        toggleSubsidiaryPopup(event) {
            // Prevent event from bubbling up
            event.stopPropagation();

            // Close all other popups first
            this.closeAllPopups();
            // Then toggle this popup
            this.showSubsidiaryPopup = !this.showSubsidiaryPopup;
        },
        handleNavigation(path) {
            // If we're already on the same path
            if (this.$route.path === path) {
                // Just scroll to top smoothly and close menus
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                this.closeAllPopups();
                if (this.isMobileNavigationVisible) {
                    this.nav_toggle();
                }
            } else {
                // Navigate to new path
                this.$router.push(path).then(() => {
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                    });
                    this.allPage();
                });
            }
        },
        handleHomeNavigation() {
            this.allPage();
            this.$router.push('/');
        },
        isCurrentRoute(path) {
            return this.$route.path === path;
        },

    },
    created() {
        if (localStorage.getItem('adspopup') != 1) {
            this.$i18n.locale = 'en';
            this.lange = this.$i18n.locale;
            localStorage.setItem('lang', this.$i18n.locale);
        }
        if (localStorage.getItem('session_start_login')) {
            this.getMyprofile();
        }
        document.addEventListener('click', this.handleOutsideClick);
    },
    beforeMount() {
        window.addEventListener('scroll', this.handleScroll)
    },
    computed: {
        getCurrentLanguageIcon() {
            switch (this.lange) {
                case 'ch':
                    return require('@/assets/icon_bars/ic_chinese.png');
                case 'en':
                default:
                    return require('@/assets/icon_bars/ic_english.png');
            }
        }
    }
}
</script>

<style scoped>
/* Desktop styles */
.active-nav::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: calc(100% - 15px); /* Subtract padding-right */
    height: 3px;
    background-color: #DE5D0A;
    border-radius: 2px;
}

.active-nav .nav_link {
    color: #DE5D0A !important;
    font-weight: 500;
    position: relative;
    display: inline-block;
}

/* Specific adjustment for blog menu item */
li[data-menu="blog"].active-nav::after {
    width: 100%;
}

.pad-top {
    position: relative;
    padding-right: 15px;
    cursor: pointer;
}

.nav_link {
    color: #20292f !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.nav_link1 {
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}
.nav_link_sub{
    color: #20292f !important;
    font-weight: 500 !important;
    font-size: 14px !important;
}

.backgroundlang_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(158, 155, 155, 0.5);
    /* Adjust opacity */
    z-index: 9999999;
    /* Ensure this is lower than .language */
}

.language {
    position: relative;
    z-index: 2;
    /* Ensure this is higher than .backgroundlang_popup */
}

/* Start Header */
header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
}

.header {
    /* padding-bottom:20px; */
    background-color: #FFFFFF !important;
    opacity: 1;

}

.noneScroll {
    display: none !important;
}

.nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.mobileScreenli {
    display: none;
}

.menu_items {
    display: flex;
    align-items: center;
    list-style: none;
    gap: 20px;
    /* margin-top: 18px; */
}

.color-highlight {
    color: #DE5D0A !important;
}

.language-item:hover {
    background-color: #FFFFFF !important;
    border-radius: 6px;
    cursor: pointer;
}

.language-item:hover .language-text p {
    color: #DE5D0A !important;
    transition: color 0.3s ease;
}

.disabled {
    pointer-events: none;
}

.sign-in-acc {
    background: #DE5D0A;
    padding: 10px 18px 10px 18px;
    border-radius: 6px;
}

.sign-in-acc:hover {
    opacity: 1;
    cursor: pointer;
    /* background-color: #EEEEEE;
        color: #1D1D1D !important;
        border: 1px solid #DE5D0A !important; */
}

li .sign-in-acc.router-link-exact-active {
    border-bottom: none !important;
    padding-bottom: 10px !important;
}

.pad-top:last-child {
    border-right: none;
}

.pad-top .language-item p:hover {
    cursor: pointer;
    color: #DE5D0A !important;
    font-weight: bold;
}

li a .nav_link {
    color: #fff !important;
    text-decoration: none;
    transition: all 0.5s ease;
}

.language-popup {
    position: absolute;
    top: 43px;
    left: -20px;
    /* width: 142px; */
    border-top: 1px solid #EEEEEE;
    width: 140px;
    background-color: #F0F2F5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px;
    /* color: white; */
    color: #2f2720;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.language-item {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.language-item:hover {
    background-color: #FFFFFF !important;
    border-radius: 6px;
    cursor: pointer;
}

.language-item:hover .language-text p {
    color: #DE5D0A !important;
    transition: color 0.3s ease;
    font-weight: bold;
}

.language-icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.language-text {
    display: flex;
    align-items: center;
    height: 100%;
}

.language-text p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #2f2620;
    line-height: 1;
}

li .user-popup a {
    color: #1D1D1D !important;
}

li .user-popup a:hover {
    color: #DE5D0A !important;
}

li .user-popup .router-link-exact-active {
    color: #DE5D0A !important;
    border-bottom: none !important;
    padding-bottom: 0 !important;
}

.user-popup {
    position: absolute;
    /* top: 100%; */
    /* left: 0; */
    /* right: 0%; */
    top: 46px;
    width: 230px;
    border-radius: 5px;
    border-top: 1px solid #EEEEEE;
    /* width: 150px; */
    background-color: #F0F2F5;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 100;
    padding: 10px;
    /* color: white; */
    color: #1D1D1D;
    font-weight: 500;
    display: flex;
    flex-direction: column;
}

.user-popup div {
    cursor: pointer;
    padding: 5px;
    /* border-bottom: 1px solid #DE5D0A; */
}

/* .user-popup div:first-child:hover {
        background-color: transparent !important;
        color: transparent !important;
    } */

.user-popup div:hover {
    background-color: #FFFFFF;
    color: #DE5D0A;
    border-radius: 6px;
}

.pad-top span:hover {
    cursor: pointer;
    color: #DE5D0A !important;
    font-weight: bold;
}

.pad-top .nav_link:hover {
    cursor: pointer;
    color: #DE5D0A !important;
    font-weight: bold;
}

#menu_toggle {
    display: none;
}

.hidden {
    display: none;
}

.noneImgScreen {
    display: none !important;
}

.mobileul {
    list-style: none;
}

.mobileul li span {
    color: #1D1D1D !important;
    font-weight: 500;
}

.mobileul li div:hover {
    /* background-color: #f0f0f0; */
    color: #DE5D0A !important;
}

.slide-enter-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: ease-in;
    -webkit-transition-timing-function: ease-in;
    -o-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
}

.slide-leave-active {
    -moz-transition-duration: 0.3s;
    -webkit-transition-duration: 0.3s;
    -o-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
    max-height: 100px;
    overflow: hidden;
}

.slide-enter,
.slide-leave-to {
    overflow: hidden;
    max-height: 0;
}

/* Responsive */
@media screen and (width < 1250px) {
    #menu_toggle {
        display: block !important;
    }

    .menu_items {
        display: none !important;
    }

    .nav {
        padding: 0 20px;
    }

    .menu_itemes {
        display: block !important;
        position: fixed;
        top: -19px;
        width: 260px;
        /* background: #DE5D0A; */
        /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
        background: #FFFFFF;
        /* height: 100vh; */
        left: -1px;
        padding: 50px 30px 30px;
        flex-direction: column;
        transition: all 0.5s ease;
        height: calc(115vh - 70px);
        /* Adjust the height according to your design */
        overflow-y: auto;
    }

    .destopApp {
        display: none;
    }

    .pad-top {
        padding-top: 15px;
        border-right: none;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 15px;
    }

    .home {
        padding-top: 30px !important;
    }

    .pad-top:last-child {
        border-bottom: none;
    }

    #menu_toggle {
        width: 20px;
        font-size: 18px;
        cursor: pointer;
    }

    .menu_items #menu_toggle {
        position: absolute;
        top: 63px;
        right: 20px;
    }

    .hidden {
        display: block;
    }

    .noneHeader1 {
        display: none !important;
    }

    .noneImgScreen {
        display: block !important;
    }

    .mobileScreenli {
        display: block !important;
    }

    .header {
        padding-bottom: 0px !important;
    }


}

@media screen and (width < 950px) {
    #menu_toggle {
        display: block !important;
    }

    .menu_items {
        display: none !important;
    }

    .nav {
        padding: 0 20px;
    }

    .menu_itemes {
        display: block !important;
        position: fixed;
        top: -19px;
        width: 260px;
        /* background: #DE5D0A; */
        /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
        background: #FFFFFF;
        /* height: 100vh; */
        left: -1px;
        padding: 50px 30px 30px;
        flex-direction: column;
        transition: all 0.5s ease;
        height: calc(115vh - 70px);
        /* Adjust the height according to your design */
        overflow-y: auto;
    }

    .destopApp {
        display: none;
    }

    .pad-top {
        padding-top: 15px;
        border-right: none;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 15px;
    }

    .home {
        padding-top: 30px !important;
    }

    .pad-top:last-child {
        border-bottom: none;
    }

    #menu_toggle {
        width: 20px;
        font-size: 18px;
        cursor: pointer;
    }

    .menu_items #menu_toggle {
        position: absolute;
        top: 63px;
        right: 20px;
    }

    .hidden {
        display: block;
    }

    .noneHeader1 {
        display: none !important;
    }

    .noneImgScreen {
        display: block !important;
    }

    .mobileScreenli {
        display: block !important;
    }

    .header {
        padding-bottom: 0px !important;
    }


}

@media screen and (width < 438px) {
    .menu_itemes {
        display: block !important;
        position: fixed;
        top: -19px;
        width: 100% !important;
        /* background: #DE5D0A; */
        /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
        background: #FFFFFF;
        /* height: 120vh; */
        left: -1px;
        padding: 50px 30px 30px;
        flex-direction: column;
        transition: all 0.5s ease;
    }

    .home {
        padding-top: 30px !important;
    }

    .noneHeader1 {
        display: none !important;
    }

    .noneImgScreen {
        display: block !important;
    }

    .mobileScreenli {
        display: block !important;
    }

}

/* End Header */
.onScroll {
    z-index: 1001;
    /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
    background: #FFFFFF;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

@media screen and (width < 500px) {
    .header {
        padding-bottom: 0px !important;
    }

    .onScroll {
        /* background: #DE5D0A !important; */
        /* background-image: linear-gradient(50deg,#DE5D0A, #FD7A23); */
        background: #FFFFFF;
        opacity: inherit;
    }

    .noneHeader1 {
        display: none !important;
    }

    .noneImgScreen {
        display: block !important;
    }

    .mobileScreenli {
        display: block !important;
    }

}

.chevron-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 0;
    /* Remove mt-1 class and handle margin in CSS if needed */
}

.chevron-icon {
    width: 20px;
    /* Adjust size as needed */
    height: 20px;
    /* Adjust size as needed */
}

.mobile-popup {
    position: relative;
    background: #F0F2F5;
    border-radius: 6px;
    margin-top: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 2;
}

.smallOnlymobile {
    /* padding: 8px 0; */
}

@media screen and (width < 950px) {
    .menu_itemes {
        z-index: 1002;
        /* Ensure menu is above other elements */
    }

    .mobile-popup {
        width: 100%;
    }

    .mobileul {
        padding: 8px;
        margin: 0;
    }

    .mobileul li {
        padding: 8px 0;
    }
}

.subsidiary-popup {
    position: absolute;
    top: 65px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background: #F5F9FF;
    border-top: 2px solid #EEEEEE;
    /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); */
    padding: 20px 0;
    z-index: 1000;
    transition: opacity 0.3s ease;
}

.subsidiary-wrapper {
    display: flex;
    /* align-items: flex-start; */
    /* gap: 30px; */
}

.subsidiary-title {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;

}

.subsidiary-title .subtitle {
    color: #666;
    font-size: 16px;
    margin-bottom: 8px;
}

.subsidiary-title .title {
    color: #1D1D1D;
    font-size: 20px;
    font-weight: bold;
    margin: 0;
}

.subsidiary-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    padding-right: 40px;
}

.subsidiary-item {
    display: flex;
    align-items: center;
    padding: 20px 0px;
    /* background: white; */
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
}

/* .subsidiary-item:hover {
    background-color: #ffffff;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
} */

.subsidiary-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    margin-right: 20px;
    border-radius: 50%;
    box-shadow: 0px 0px 4px 0px #00000040;

}

.subsidiary-icon img {
    width: 36px;
    height: 36px;
    object-fit: contain;
}

.subsidiary-content h4 {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    color: #1D1D1D;
}

.subsidiary-content p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #666;
}

@media screen and (max-width: 991px) {
    .subsidiary-grid {
        grid-template-columns: 1fr;
        padding: 0 20px;
    }

    .subsidiary-title {
        padding: 0 20px;
        text-align: center;
        margin-bottom: 20px;
    }

    .subsidiary-wrapper {
        flex-direction: column;
    }
}

@media screen and (max-width: 768px) {
    .subsidiary-popup {
        padding: 20px 0;
    }

    .subsidiary-title .title {
        font-size: 24px;
    }
}

.active-nav {
    position: relative;
}

.active-nav::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: calc(100% - 15px);
    height: 3px;
    background-color: #DE5D0A;
    border-radius: 2px;
}

.active-nav .nav_link {
    color: #DE5D0A !important;
    font-weight: 500;
    position: relative;
    display: inline-block;
}

/* Specific adjustment for blog menu item */
li[data-menu="blog"].active-nav::after {
    width: 100%;
}

.pad-top {
    position: relative;
    cursor: pointer;
}

.nav_link {
    transition: color 0.3s ease;
}

/* Hover effect */
.pad-top:hover .nav_link {
    color: #DE5D0A;
}

.contact-menu::after {
    display: none !important; /* Ensures no underline appears for contact */
}

.contact-menu .nav_link:hover {
    color: #DE5D0A !important; /* Keeps the hover effect */
}

/* Mobile styles */
@media screen and (max-width: 1250px) {
    .active-nav::after {
        display: none !important; /* Remove underline on mobile */
    }
    
    .active-nav .nav_link {
        color: #DE5D0A !important; /* Keep the active text color */
    }
}
@media screen and (max-width: 950px) {
    .active-nav::after {
        display: none !important; /* Remove underline on mobile */
    }
    
    .active-nav .nav_link {
        color: #DE5D0A !important; /* Keep the active text color */
    }
}
</style>