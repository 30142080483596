<template>
    <div v-if="bookingData" class="ticket-container">
        <button class="close-btn" aria-label="Close Ticket Detail" @click="$emit('close')">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18 6L6 18" stroke="#424245" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                <path d="M6 6L18 18" stroke="#424245" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
        </button>
        <div class="ticket-content">
            <hr />
            <div class="ticket-body">
                <div class="qr-section">
                    <!-- Show QR code based on currentQRCodeIndex -->
                    <div class="qr-code" v-if="bookingData.bookingSeatDetailList[currentQRCodeIndex]">
                        <img :src="generateQRCode(bookingData.code, bookingData.bookingSeatDetailList[currentQRCodeIndex].seatNumber)"
                            alt="QR Code" />
                        <div class="seat-info">
                            <span>
                                {{ bookingData.bookingSeatDetailList[currentQRCodeIndex].seatNumber }},
                                {{ bookingData.bookingSeatDetailList[currentQRCodeIndex].gender }}
                            </span>
                            <!-- Arrow to toggle QR codes -->
                            <div @click="showNextQRCode">
                                <img src="@/assets/travel_package/arrow-right-blue.svg" alt="Arrow" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row ticket-details">
                    <div class="route-info">
                        <div style="width: 50px;">
                            <img style="width: 100%; object-fit: contain;"
                                :src="bookingData.journeyType === 1 ? require('@/assets/vireak-buntham.png') : bookingData.journeyType === 3 ? require('@/assets/airbus.png') : require('@/assets/vireak-buntham.png')"
                                :alt="bookingData.journeyType === 1 ? 'Bus' : bookingData.journeyType === 3 ? 'Airbus' : 'Bus'" />
                        </div>
                        <div class="destination">{{ bookingData.destinationFrom }} - {{ bookingData.destinationTo }}
                        </div>
                    </div>
                    <div class="info-grid">
                        <div class="info-column" style="width: 200px;">
                            <div>Code: {{ bookingData.code }}</div>
                            <div>Booked Date: {{ bookingData.bookingDate }}</div>
                            <div>Phone Number: {{ bookingData.telephone }}</div>
                            <div>Boarding Point: <a
                                    :href="generateGoogleMapsUrl(bookingData.boardingPointLat, bookingData.boardingPointLong)"
                                    class="link" aria-label="View Boarding Map" target="_blank"
                                    rel="noopener noreferrer">
                                    View Map
                                </a></div>
                            <div>Payment Type: <span class="highlight">{{ bookingData.paymentType }}</span></div>
                        </div>
                        <div class="info-column">
                            <div>Departure date: {{ bookingData.travelDate }} ({{ bookingData.departure }})</div>
                            <div>Transition ID: {{ bookingData.transactionId }}</div>
                            <div>Seat No: {{ bookingData.bookingSeatDetailList.map(seat => seat.seatNumber).join(', ')
                                }}</div>
                            <div>Drop off Point: <a
                                    :href="generateGoogleMapsUrl(bookingData.dropOffPointLat, bookingData.dropOffPointLong)"
                                    class="link" aria-label="View Boarding Map" target="_blank"
                                    rel="noopener noreferrer">
                                    View Map
                                </a></div>
                            <div>
                                <a :href="dynamicLink" class="link" aria-label="Take Survey"
                                    @click.prevent="navigateToFeedback">Take a survey</a>
                            </div>
                        </div>
                    </div>
                    <div class="ticket-note">
                        <!-- * Please show your e-ticket to our staff at the ticket counter to get your physical Lucky Ticket -->
                    </div>
                </div>
            </div>

            <div class="ticket-footer">
                <div class="customer-info">
                    <h3>Information Customer</h3>
                    <div class="info-table">
                        <div class="table-header">
                            <div>Nationality</div>
                            <div>Seat No.</div>
                            <div>Gender</div>
                        </div>
                        <div class="table-row" v-for="seat in bookingData.bookingSeatDetailList" :key="seat.seatNumber">
                            <div>Cambodian</div>
                            <div>{{ seat.seatNumber }}</div>
                            <div>{{ seat.gender }}</div>
                        </div>
                    </div>
                </div>

                <div class="payment-summary">
                    <h3>Summary Payment</h3>
                    <div class="payment-details">
                        <div class="payment-row">
                            <span>Grand Total</span>
                            <span>{{ bookingData.subTotal }}</span>
                        </div>
                        <div class="payment-row">
                            <span>Discount
                                ({{ bookingData.discountPercent ? Number(bookingData.discountPercent).toFixed(0) : 0
                                }}%)</span>
                            <span>{{ bookingData.discount }}</span>
                        </div>
                        <div class="payment-row" v-if="bookingData.luckDrawFee && bookingData.luckDrawFee > 0">
                            <span>Lucky Ticket</span>
                            <span>{{ bookingData.luckDrawFee }}</span>
                        </div>
                        <div class="payment-row">
                            <span>Total</span>
                            <span>{{ bookingData.totalAmount }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
export default {
    name: 'TicketHistoryDetailComponent',
    data() {
        return {
            bookingData: null, // Booking data
            currentQRCodeIndex: 0, // Index of the currently displayed QR code
            ticketId: null,
        };
    },
    computed: {
        dynamicLink() {
            return this.ticketId
                ? `/feed-back?ticketId=${encodeURIComponent(this.ticketId)}`
                : "/feed-back";
        },
    },

    methods: {
        navigateToFeedback() {
            // Navigate programmatically to the dynamic URL
            if (this.ticketId) {
                window.location.href = `/feed-back?ticketId=${encodeURIComponent(this.ticketId)}`;
            } else {
                window.location.href = "/feed-back";
            }
        },
        fetchBookingData() {
            const selectedTicketId = localStorage.getItem('selectedTicketId');
            this.id = selectedTicketId;
            if (!selectedTicketId) {
                console.error('selectedTicketId is not set in localStorage.');
                return;
            }
            const url = `${this.$url}booking/find/${selectedTicketId}`;
            const formData = new FormData();
            formData.append('id', selectedTicketId);

            const config = {
                method: "POST",
                headers: {
                    ...this.$header,
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
                url,
            };

            axios(config)
                .then(response => {
                    const responseData = response.data;
                    if (responseData.header?.result && responseData.body?.status) {
                        this.bookingData = responseData.body.data[0];
                        console.log("data ticket history", this.bookingData);
                    } else {
                        console.error("Failed to fetch booking data:", responseData.body.message);
                    }
                })
                .catch(error => {
                    console.error("Error fetching booking data:", error);
                });
        },
        generateGoogleMapsUrl(lat, lng) {
            // Generate the Google Maps URL with the given latitude and longitude
            return `https://www.google.com/maps?q=${lat},${lng}`;
        },
        showNextQRCode() {
            // Cycle through QR codes
            if (this.bookingData) {
                this.currentQRCodeIndex =
                    (this.currentQRCodeIndex + 1) % this.bookingData.bookingSeatDetailList.length;
            }
        },
        generateQRCode(code, seatNumber) {
            return `https://api.qrserver.com/v1/create-qr-code/?data=${code}_${seatNumber}&size=150x150`;
        },



    },
    mounted() {
        this.ticketId = localStorage.getItem('selectedTicketId');
        this.fetchBookingData();
    },
};
</script>

<style>
.ticket-container {
    width: 100%;
    /* max-width: 72rem; */
    /* Use max-width instead of width */
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.ticket-content {
    padding-top: 45px;
    overflow-y: auto;
    /* height: 85vh; */
}

.close-btn {
    position: absolute;
    right: 10px;
    top: 8px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
}

.ticket-body {
    display: flex;
    gap: 2rem;
    padding: 1rem 7rem 1rem 7rem;
    flex-wrap: wrap;
    overflow-y: auto;
}

.qr-section {
    flex: 0 0 250px;
}

.qr-code {
    background-color: #D9D9D9;
    padding: 1rem;
    border-radius: 8px;
}

.qr-code img {
    width: 100%;
    height: auto;
}

.seat-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 3px;
    border-radius: 4px;
}

.ticket-details {
    display: flex;
    flex: 1;
    /* justify-content: center; */
    /* flex-direction: row; */
    /* width: 600vmin; */
    margin-left: 3rem;
}

.route-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}

.destination {
    font-size: 1.25rem;
    font-weight: 700;
    color: #1D1D1D;
}

.info-grid {
    display: flex;
    flex-wrap: wrap;
    /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
    gap: 2rem;
}

.info-column>div {
    margin-bottom: 1rem;
    color: #1D1D1D;
}

.link {
    color: #DE5D0A;
    font-weight: 600;
    text-decoration: underline;
}

.highlight {
    color: #DE5D0A;
    font-weight: 600;
}

.ticket-note {
    color: #312783;
    font-size: 0.9rem;
}

.ticket-footer {
    border-top: 1px solid #C6C6C6;
    padding: 2rem 7rem 0 7rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
}

.info-table {
    margin-top: 1rem;
}

.table-header,
.table-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.5rem 0;
}

.table-header {
    font-weight: 600;
}

.payment-details {
    margin-top: 1rem;
}

.payment-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
    .ticket-footer {
        border-top: 1px solid #C6C6C6;
        padding: 2rem 1rem 0 1rem;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        gap: 2rem;
    }

    .qr-section {
        flex: 0 0 auto;
        max-width: 300px;
        margin: 0 auto;
    }

    .ticket-details {
        flex: 1;
        /* width: 600px; */
        /* margin-left: ; */
    }

    .ticket-body {
        flex-direction: column;
        display: flex;
        gap: 2rem;
        padding: 1rem 1rem 1rem 1rem;
        flex-wrap: wrap;
        overflow-y: auto;
    }

    .route-info {
        flex-direction: column;
        text-align: center;
    }

    .destination {
        font-size: 1.1rem;
    }

    .ticket-content {
        /* margin-top: -35px; */
        padding-top: 50px;
        padding-bottom: 30px;
    }
}

@media (max-width: 480px) {

    .table-header,
    .table-row {
        grid-template-columns: 1fr;
        gap: 0.5rem;
        /* text-align: center; */
        border-bottom: 1px solid #eee;
        padding-left: 1rem;
    }

    .payment-row {
        flex-direction: column;
        /* align-items: center; */
        padding-left: 1rem;
        gap: 0.25rem;
    }

    .ticket-content {
        /* margin-top: -35px; */
        padding-top: 0px;
        padding-bottom: 30px;
    }

    .close-btn {
        position: static;
        display: block;
        margin: 0 0 1rem auto;
    }
}

@media screen and (width < 1500px) {}

@media screen and (width < 1460px) {}

@media screen and (width < 1430px) {}

@media screen and (width < 1400px) {}

@media screen and (width < 1360px) {}

@media screen and (width < 1330px) {}

@media screen and (width < 1265px) {
    .ticket-body {
        padding: 1rem 5rem 1rem 5rem;
    }

}

@media screen and (width < 1210px) {
    .ticket-body {
        padding: 1rem 6rem 1rem 6rem;
    }

}

@media screen and (width < 1200px) {
    .ticket-body {
        padding: 1rem 5rem 1rem 5rem;
    }

}

@media screen and (width < 1160px) {
    .ticket-body {
        padding: 1rem 4rem 1rem 4rem;
    }

}

@media screen and (width < 1140px) {
    .ticket-body {
        padding: 1rem 3rem 1rem 3rem;
    }

    .ticket-details {
        flex: 1;
        margin-left: 1rem;
    }

}

@media screen and (width < 970px) {
    .ticket-body {
        padding: 1rem 1rem 1rem 1rem;
    }

    .ticket-details {
        flex: 1;
        margin-left: 1rem;
    }

}

@media screen and (width < 890px) {}
</style>
