<template>
    <!-- <div class="loading" v-if="loading">
        <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
    </div> -->
    <div class="travel_his">
        <div class="container-fluid" style="background: #f2f2f2;">
            <div class="containers">
                <div class="row header-profile">
                    <div class="d-flex"
                        style="border-bottom: 1px solid #C6C6C6; width: 100%; margin-left: 6px; margin-right: 6px;">
                        <!-- <div class="account-ticket account-ticket-1" :class="{ active: activeTab == '1' }" @click="toggleTab('1')">
                            Account Ticket
                        </div> -->
                        <div class="account-ticket" style="margin-left: 30px;" :class="{ active: activeTab == '1' }"
                            @click="toggleTab('1')">
                            Travel Package History
                        </div>
                    </div>
                    <!-- <div v-show="activeTab==1" class="account-ticket-history mt-8">
                        <div class="row">
                            <div class="count-ticket mr-4 mt-3">
                                <img src="@/assets/travel_package/Ellipse 15.png" alt="">
                                <div>
                                    0/10
                                </div>
                            </div>
                            <div class="inf-account d-flex flex-column justify-space-around">
                                <div>
                                <span class="tel">Tel:</span> <span class="number"> 012 345 678</span>
                                </div>
                                <div class="box-account">
                                    General Account
                                </div>
                            </div>
                        </div>
                        <div class="mt-6">
                            <div class="mb-3" style="font-weight: bold;">Condition <span style="color: #DE5D0A;;"> *</span></div>
                            <ul style="list-style-type: decimal;">
                                <li>Buy 10 tickets you will become VIP Customer.</li>
                                <li>You will get 1 ticket for free on every 11th ticket.</li>
                                <li>VIP customer get $1 discount on each ticket.</li>
                            </ul>
                        </div>
                    </div> -->
                    <div v-show="activeTab == 1" class="travel-package-history mt-8"
                        :style="{ height: listHistory && listHistory.length === 0 ? 'auto' : 'auto', paddingBottom: listHistory && listHistory.length === 0 ? '0px' : '10vh' }">
                        <div v-if="listHistory && listHistory.length > 0">
                            <div class="history-record" v-for="(item, i) in listHistory" :key="i">
                                <div class="row">
                                    <h3 class="title-package">{{ item.packageName }}</h3>
                                </div>
                                <div class="row mt-10">
                                    <div>
                                        <img :src="getImageUrl(item.photo)"
                                            :alt="item.packageName || 'Travel Package Photo'" width="160" height="160"
                                            @error="handleImageError" style="object-fit: contain;">
                                    </div>
                                    <div class="information-travel" style="margin-top: 25px; margin-left: 20px;">
                                        <ul>
                                            <li>{{ item.name }}</li>
                                            <li v-if="item.telephone">{{ item.telephone }}</li>
                                            <li>{{ item.email }}</li>
                                            <li>Price ${{ item.packagePrice }}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="row mt-8 inf-invoice">
                                    <div class="col-lg-10 col-md-10 col-sm-10 info-package-buy d-flex align-center">
                                        <div class="img-car d-flex align-center">
                                            <img src="@/assets/travel_package/truck.svg" alt="">
                                        </div>
                                        <div class="title-car ml-5">
                                            <div class="d-flex">
                                                <span>Code Travel: {{ item.packageCode }}</span>
                                                <span class="d-flex justify-center copy-past"
                                                    @click="Copytitle(item.packageCode)">
                                                    <img src="@/assets/travel_package/copy.svg" alt=""
                                                        style="margin-left: 10px; margin-right: 3px;">
                                                    {{ copyStatus }}
                                                </span>
                                            </div>
                                            <div>
                                                <span>Issue Date: {{ item.packageDate }}</span>
                                            </div>
                                            <div>
                                                <span>Expired Date: {{ item.packageExpired }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-6">
                                    <div class="col-10 mb-3">
                                        <span style="font-weight: bold; color: #312783;">Term & Condition:</span>
                                        <div class="mt-2" style="color: #424245;">
                                            <ul style="padding-left: 0;">
                                                <li v-for="(paragraph, index) in getParagraphs(item.termCondition)"
                                                    :key="index" v-html="paragraph" style="list-style-type: none;"></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else class="no-data">
                            <div class="text-center" style="margin-top: 10%;">
                                <img src="@/assets/travel_package/not-found.png" alt="">
                                <h3 style="color: #DE5D0A;">Data Not Found</h3>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            activeTab: 1,
            copyStatus: 'Copy',
            listHistory: [],
            loading: false,
        }
    },
    computed: {

    },

    methods: {
        getParagraphs(description) {
            // Split the description into paragraphs using \n as delimiter
            return description.split('\n').map(paragraph => paragraph.trim());
        },
        Copytitle(text) {
            this.copyStatus = 'Copied!';
            const textarea = document.createElement('textarea');
            textarea.value = text;
            document.body.appendChild(textarea);

            // Select and copy the text from the textarea
            textarea.select();
            document.execCommand('copy');

            // Remove the textarea from the DOM
            setTimeout(() => {
                this.copyStatus = 'Copy';
            }, 1000); // You can adjust the delay as needed
            document.body.removeChild(textarea);

        },
        async getTravelPacakageHistory() {
            try {
                this.loading = true;
                const url = this.$url + `travel-package/packageBuyList`;
                const data = {
                    'session': localStorage.getItem('session_start_login'),
                }

                const config = {
                    method: "POST",
                    headers: {
                        ...this.$header,
                        "Content-Type": "multipart/form-data"
                    },
                    data: data,
                    url,
                };

                const response = await axios(config);

                if (response.status === 200 && response.data.header.result && response.data.header.statusCode === 200) {
                    // Reverse the array to show newest items first
                    this.listHistory = response.data.body.reverse();

                    // Add detailed logging for photo URLs
                    this.listHistory.forEach((item, index) => {
                        console.log(`Item ${index + 1} photo URL:`, item.photo);
                        console.log(`Full item data:`, item);
                    });
                }
            } catch (error) {
                console.error("Error fetching travel package history:", error);
            } finally {
                this.loading = false;
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        getImageUrl(photo) {
            if (!photo) {
                return require('@/assets/profile/default-profile.svg');
            }

            // If the photo is a full URL
            if (photo.startsWith('http://') || photo.startsWith('https://')) {
                return photo;
            }

            // If it's a relative path, prepend your API base URL
            return this.$url + photo;
        },

        handleImageError(e) {
            e.target.src = require('@/assets/profile/default-profile.svg');
        },
    },

    created() {
        this.scrollToTop();
        this.getTravelPacakageHistory();
    }
}
</script>
<style scoped>
.travel_his {
    padding-top: 55px;
}

.header-profile {
    padding-top: 35px;
    box-sizing: border-box;

}

.inf-account .tel {
    color: #1D1D1D !important;
    font-size: 16px;
}

.inf-account .number {
    color: #312783 !important;
    font-size: 16px;
}

.account-ticket-history {
    margin-left: 65px;
}

.account-ticket-history ul li {
    padding-bottom: 4px;
}

.account-ticket.active {
    border-bottom: 3px solid #312783;
}

.account-ticket:hover {
    color: #312783 !important;
}

.box-account {
    background: #FF0000;
    color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 7px 15px 7px 15px;
}

.count-ticket {
    position: relative;
}

.count-ticket div {
    position: absolute;
    left: 32%;
    top: 35%;
}

.title-car div {
    padding: 2px;
}

.account-ticket {
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    color: #000000;
    overflow-y: auto;

}

.travel-package-history {
    margin-left: 65px;
    width: 100%;
}

.travel-package-history .title-package {
    color: #312783;
}

.information-travel ul li {
    color: #6A6969;
    padding: 1px;
}

.info-package-buy {
    background-color: #312783;
    color: #FFFFFF;
    padding: 12px 10px 12px 25px;

}

.copy-past {
    cursor: pointer;
}
</style>