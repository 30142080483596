<template>
    <div>
        <div class="product-serive">
            <div class="containers">
                <div class="serives">
                    <p class="product-ser">{{ $t('message.vetProductServices') }}</p>
                    <p class="tittle">{{ $t('message.desVetProductServices') }}</p>
                </div>
                <div class="title">
                    <p class="text">{{ $t('message.vetTravelPassengerTransprtationService') }}</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/luxuryhotel.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/luxuryseat.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/luxuryseat.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                        <ul>
                            <li><span class="name">{{ $t('message.luxuryHotelBusPageService') }}</span>
                                <span>{{ $t('message.luxuryHotelBusPageServiceP') }}</span></li>
                            <li><span class="name">{{ $t('message.vipVanToyotaHiace') }}</span>
                                <span>{{ $t('message.vipVanToyotaHiaceP') }}</span></li>
                            <li><span class="name">{{ $t('message.vetAirBusPageService') }}</span>
                                <span>{{ $t('message.vetAirBusPageServiceP') }}</span></li>
                            <li><span class="name">{{ $t('message.busRentalService') }}</span>
                                <span>{{ $t('message.busRentalServiceP') }}</span></li>
                            <li><span class="name">All passengers are our responsibility, they are taken care and
                                    insured.</span></li>
                            <li>
                                <span>{{ $t('message.AllpassengerAreOur') }}</span><br>
                                <div style="padding-left: 20px;color: #000000;">
                                    <span>{{ $t('message.whereCaWeBuyATicket') }}</span><br>
                                    <span>{{ $t('message.theNearbyBranche') }}</span><br>
                                    <span>{{ $t('message.HotlinePhoneNumber') }}</span><br>
                                    <span>{{ $t('message.appBookingName') }}</span><br>
                                    <span>{{ $t('message.websiteName') }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="title mt-8">
                    <p class="text">{{ $t('message.vetLogistic') }}</p>
                </div>
                <div class="row">
                    <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/check-box.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/bus-de.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="imgWid">
                                    <img src="@/assets/products/bus-world.svg" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-6 col-sm-6 col-xs-12">
                        <ul>
                            <li><span class="name">{{ $t('message.vetExpressServiceToanChet') }}</span>
                                <span>{{ $t('message.vetExpressServiceToanChetP') }}</span></li>
                            <li><span class="name">{{ $t('message.vetNormalService') }}</span>
                                <span>{{ $t('message.vetNormalServiceP') }}.</span></li>
                            <li><span class="name">{{ $t('message.goodsTransferFreezerTruck') }}</span>
                                <span>{{ $t('message.goodsTransferFreezerTruckP') }}</span></li>
                            <li><span class="name">{{ $t('message.deliveryService') }}</span>
                                <span>{{ $t('message.deliveryServiceP') }}</span></li>
                            <li><span class="name">{{ $t('message.cashOnDelivery') }}</span>
                                <span>{{ $t('message.cashOnDeliveryP') }}</span></li>
                            <li><span class="name">{{ $t('message.insuredGoodTransfer') }}</span>
                                <span>{{ $t('message.insuredGoodTransferP') }}</span></li>
                            <li><span class="name">{{ $t('message.GoodsTransferCrossingBorder') }}</span>
                                <span>{{ $t('message.GoodsTransferCrossingBorderP') }}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    created() {
        this.scrollToTop();
    }
}
</script>

<style scoped>
.product-serive {
    padding-top: 120px;
    padding-bottom: 50px;
}

.serives .product-ser {
    font-size: 36px;
    font-weight: 600;
    color: #1D1D1D;
}

.serives .product-ser span {
    color: #DE5D0A;
}

.tittle {
    font-weight: 600;
    font-size: 16px;
    color: #424245;
}

.title .text {
    font-size: 24px;
    font-weight: 600;
    color: #312783;
}

.imgWid {
    width: 100%;
    height: 200px;
}

.imgWid img {
    width: 100%;
    height: 100%;
}

ul li {
    font-size: 16px;
    font-weight: 400;
    color: #424242;
    margin-top: 25px;
}

.name {
    color: #DE5D0A;
}

@media(width < 500px) {
    .product-serive {
        padding-top: 100px;
    }

    .serives .product-ser {
        font-size: 20px;
    }

    .tittle {
        font-size: 15px;
    }

    .title .text {
        font-size: 17px;
    }

    ul li {
        font-size: 14px;
    }
}
</style>