<template>
    <div class="company-profile">
        <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div>
        <div v-else>
            <profile-company />
        </div>
    </div>
</template>

<script>
import ProfileCompany from '@/components/profile/ProfileCompany.vue';
export default {
    components: {
        ProfileCompany,
    },
    data() {
        return {
            loading: true,
            reloadCompanyProfile: "",

        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0);
        },
    },
    created() {
        localStorage.setItem('reloadHome', 'false');
        localStorage.setItem('reloadRestore', 'false');
        localStorage.setItem('reloadRetal', 'false');
        localStorage.setItem('reloadBlog', 'false');
        localStorage.setItem('reloadGallers', 'false');
        localStorage.setItem('reloadTerm', 'false');
        localStorage.setItem('reloadPrivacy', 'false');

        this.reloadCompanyProfile = localStorage.getItem('reloadCompanyProfile');
        this.scrollToTop();
        if (this.reloadCompanyProfile != 'true') {
            localStorage.setItem('reloadCompanyProfile', 'true');
        }
        if (localStorage.getItem('reloadCompanyProfile') == 'true') {
            setTimeout(() => {
                this.loading = false;
            }, 1000);
        }
    }
}
</script>

<style>
.company-profile {
    margin-top: 28px;
}

@media screen and (width < 950px) {
    .company-profile {
        margin-top: -15px;
    }



}

@media screen and (width < 620px) {
    .company-profile {
        margin-top: -15px;
    }

}

@media screen and (width < 500px) {
    .company-profile {
        margin-top: -15px;
    }
}

@media(width < 450px) {
    .company-profile {
        margin-top: -15px;
    }






}

@media screen and (width < 438px) {
    .company-profile {
        margin-top: -15px;
    }



}
</style>