<template>
    <div class="buytravelpackage">
        <!-- <div class="loading" v-if="loading">
            <v-progress-circular indeterminate color="#de5d0a"></v-progress-circular>
        </div> -->

        <div class="container-fluid" style="background: #f2f2f2;">

            <div class="containers" style="padding-bottom: 70px; padding-top: 50px;">

                <div class="row travelpackageheader">
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div>
                            <h1 class="text-center" style="color: #1D1D1D;">Travel Package </h1>
                        </div>
                        <div class="d-flex justify-center mt-6">
                            <div class="d-flex mobile-screen">
                                <div>
                                    <div><img src="@/assets/travel_package/personal-info.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number"
                                            style="background-color: #DE5D0A;   border: 1px solid #DE5D0A;">1</div> <img
                                            src="@/assets/travel_package/Line.svg" alt="" style="margin-left: 2px;">
                                    </div>

                                    <div class="title-pagination mt-1">Package Information</div>
                                </div>
                                <div>
                                    <div><img src="@/assets/travel_package/register-info.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="d-flex justify-center number-img">
                                        <div class="number box-color">2</div> <img
                                            src="@/assets/travel_package/Line.svg" alt="">
                                    </div>

                                    <div class="title-pagination mt-1">Register Information </div>
                                </div>
                                <div>
                                    <div><img src="@/assets/travel_package/payment.svg" alt="" width="25px"
                                            style="margin-left: 2px;"></div>
                                    <div class="number-img">
                                        <div class="number box-color">3</div>
                                    </div>

                                    <div class="title-pagination mt-1">Confirm Payment</div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div>

                <transition name="slide" mode="out-in">

                    <div key="box3">
                        <div v-html="paymentFormPost" class="popupaba"></div>
                        <div class="row travelpackageheader pl-lg-10 pl-md-10">
                            <div class="col-lg-6 col-md-7 col-sm-12">
                                <div class="passDetails">
                                    <!-- <div class="number">2</div> -->
                                    <div class="title-pass">Payment Methods</div>
                                </div>

                                <!-- <form action=""> -->
                                <div class="select-radio ml-3" style="margin-top: -12px;">
                                    <v-radio-group v-model="radioGroup">
                                        <div class="card-select-payment">
                                            <div class="img-card">
                                                <img src="@/assets/KHQR.svg" alt="">
                                            </div>
                                            <div class="text">
                                                <div class="title" style="line-height: 20px;">
                                                    Payment KHQR
                                                </div>
                                                <div class="txt">
                                                    Scan to pay with member bank app
                                                </div>
                                            </div>
                                            <div class="radios">
                                                <v-radio color="#DE5D0A" :value="5"
                                                    style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                            </div>
                                        </div>
                                        <!-- <div class="card-select-payment">
                                        <div class="img-card">
                                            <img src="@/assets/ABA_PAY.svg" style="width: 40px;height: 30px;border-radius: 4px;" alt="">
                                        </div>
                                        <div class="text">
                                            <div class="title"  style="line-height: 20px;">
                                                ABA Pay
                                            </div>
                                            <div class="txt">
                                                Scan to pay with member bank app
                                            </div>
                                        </div>
                                        <div class="radios">
                                            <v-radio color="#DE5D0A" :value="4" style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                        </div>
                                    </div> -->
                                        <div class="card-select-payment">
                                            <div class="img-card">
                                                <img src="@/assets/generic.svg" alt="">
                                            </div>
                                            <div class="text">
                                                <div class="title">
                                                    Credit / Debit Card
                                                </div>
                                                <div class="txt">
                                                    <img src="@/assets/4_Cards.svg" alt="">
                                                </div>
                                            </div>
                                            <div class="radios">
                                                <v-radio color="#DE5D0A" :value="6"
                                                    style="position: absolute;top: 0;right: 0;width: 100%;height: 100%;padding-left: 88%;"></v-radio>
                                            </div>
                                        </div>

                                    </v-radio-group>
                                </div>
                                <!-- </form> -->
                                <!-- <div class="row mt-4 ml-2">
                                <p style="font-size: 18px; font-weight: 500;">Cancellation Policy</p>
                                <span> <span style="font-weight: 600;"> NOTE:</span> Due to time zone differences, the confirmed cancellation date is based on the supplier’s local time. Please allow 2–5 business days to process your cancellation request, after which the cancellation fee will be charged according to the product’s cancellation policy. Once the cancellation is confirmed, you will receive your refund within 14 working days.</span>
                            </div> -->


                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-10 col-md-10 col-sm-10 d-flex justify-lg-center justify-md-center">
                                    <v-btn :disabled="disable" @click="getPaymentTravelPk"
                                        style="border-radius: 6px; width:  35%; outline: none;" color="#DE5D0A"
                                        height="40px" class="text-capitalize btn-width reposo-height">
                                        <span style="color: #FFF; font-size: 14px !important;"> {{ titleButton }}
                                        </span>
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </div>

                </transition>

            </div>
        </div>
    </div>
</template>
<script src="https://checkout.payway.com.kh/plugins/checkout2-0.js"></script> //production
<!-- <script src="https://checkout.payway.com.kh/plugins/checkout2-0-dev.js"></script> // qa -->
<script>
import axios from 'axios';
import qs from 'qs';
import { loadScript } from "vue-plugin-load-script";

  export default{
    data(){
        return{
            
            radioGroup: 5,
            transactionId: this.$route.query.code,
            totalAmount: this.$route.query.price,
            paymentFormPost: "",
            titleButton:'Buy Package',
            info:[],
            disable:false,
            urlABA: 'https://checkout.payway.com.kh/plugins/checkout2-0.js', // production
            // urlABA: 'https://checkout.payway.com.kh/plugins/checkout2-0-dev.js', // qa
        }
    },
    
    watch:{
    
    },
    methods: {
        async getPaymentTravelPk() {
            if(this.radioGroup == 0) {
                alert("Please select payment type before click button pay now.");
            }else{
                this.titleButton="Buying...";
                this.disable = true;
                const data = { 
                    'code': this.transactionId,
                    'paymentMethodId': parseInt(this.radioGroup),
                    'totalAmount': this.totalAmount
                }
                const url = this.$url + `travel-package/processPayment`;

                let config = {
                    method: "POST",
                    headers: this.$header,
                    data: qs.stringify(data),
                    url
                };
                axios(config).then((response) => {
                    if (response.status == 200) {
                        if(response.data.header.result == true && response.data.header.statusCode == 200) {  
                            this.titleButton = "Buy Package"
                            this.info = response.data.body;
                            this.disable = false;
                              if(this.info.status == 1){ // Ture
                                // let paymentToken = this.info.token;
                                // if(this.radioGroup == 4){
                                //     if(response.data.body.request != "") {
                                //         window.location.href = response.data.body.request;
                                //     }else{
                                //         alert("Invaild Payment Method");
                                //     }
                                // } else {
                                    this.paymentFormPost = response.data.body.request;
                                    loadScript(this.urlABA)
                                    .then(() => {
                                        AbaPayway.checkout();
                                    })
                                    // .then(()=>{
                                    //     this.router.push("/payment-travel-pksuccess");
                                    // })
                                    .catch(() => {
                                        console.log("error process payment aba");
                                    });
                                    
                                // }
                            } else { // Session Expired
                                alert("Session Expired");
                                this.$router.push("/");
                            }
                        } else { // Error
                            alert("Sorry, the payment process failed. Please try it again.");
                            // this.$router.push("/");
                        }
                    }else {
                        console.log('Not Status 200');
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        },
        scrollToTop() {
            window.scrollTo(0,0);
        }
    },
    created(){ 
        this.scrollToTop();
    },
    mounted() {
        loadScript(this.urlABA);
    },
    beforeUnmount() {
        window.onpopstate = null;
    },
  }
</script>
<style scoped>
.buytravelpackage {
    padding-top: 55px;
}

.travelpackageheader {
    padding-top: 35px;
    padding-bottom: 30px;
    /* box-sizing: border-box; */
}

.select-radio .card-select-payment {
    position: relative;
    background: #EEEEF3;
    margin-top: 12px;
    padding: 17px;
    border-radius: 6px;
    display: flex;
    align-items: center;
}

.select-radio .card-select-payment .text .title {
    font-weight: 600;
    font-size: 12px !important;
    color: #424245;
}

.passDetails {
    display: flex;
    align-items: center;
}

.passDetails .number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #312783;
    background: #312783;
}

.passDetails .title-pass {
    margin-left: 12px;
    font-weight: 600;
    font-size: 18px;
    color: #0c2f54;
}

.select-radio .card-select-payment .text .txt {
    font-weight: 400;
    font-size: 12px !important;
    color: #6E6E73;
}

.select-radio .card-select-payment .img-card {
    width: 10%;
}

.select-radio .card-select-payment .text {
    width: 80%;
}




.number {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #312783;
    background: #312783;
}

.box-color {
    background-color: rgb(222, 93, 10);
    border: 1px solid rgb(222, 93, 10);
}

.title-pagination {
    margin-left: -40px;
    color: #1D1D1D;
}

.mobile-screen {
    margin-left: 32px !important;
}


.hover-color:hover {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 12px;
    color: #ffffff;
    background: #DE5D0A;
}




@media screen and (max-width: 961px) and (min-width: 700px) {

    .number-img img {
        width: 230px;

    }
}

@media screen and (width < 700px) {

    /* .buytravelpackage {
          margin-top: -20px;
      } */
    .number-img img {
        width: 160px;
    }
}

@media screen and (width < 600px) {
    .title-pagination {
        font-size: 10px;
        margin-left: -25px;
    }

    .select-radio .card-select-payment {
        padding: 8px;
    }

    .passDetails .title-pass {
        font-size: 13px;
    }

    .select-radio .card-select-payment .text {

        padding-left: 18px;
    }

}

@media screen and (width < 500px) {


    .travelpackageheader {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .number-img img {
        width: 100px;

    }

    .title-pagination {
        font-size: 10px;
        margin-left: -25px;
    }

    .select-radio .card-select-payment {
        padding: 8px;
    }

    .passDetails .title-pass {
        font-size: 13px;
    }

    .select-radio .card-select-payment .text {

        padding-left: 18px;
    }


}

@media screen and (width < 438px) {
    /* .buytravelpackage {
          margin-top: -20px;
      } */

    .travelpackageheader {
        padding-top: 20px;
        padding-bottom: 40px;
        opacity: 1;
    }

    .number-img img {
        width: 100px;
    }

    .title-pagination {
        font-size: 10px;
        margin-left: -25px;
    }

    .mobile-screen {
        margin-left: 5px !important;
    }





}

@media screen and (width < 372px) {
    .mobile-screen {
        margin-left: 17px !important;
    }

}
</style>