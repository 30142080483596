<template>
  <div>
    <div class="boarding">
      <div class="containers">
        <div class="groupTitle">
          <p>{{ $t('message.messageFrom') }}</p>
          <h2>{{ $t('message.chairmanCeo') }}</h2>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="groupImg">
              <img src="@/assets/ceo-profile.png" alt="ceo profile" />
            </div>
          </div>
          <div class="col-lg-8 col-md-6 col-sm-12">
            <div class="text-des">
              <p v-html="$t('message.desMessageFromChairmanCeoP')"></p>
            </div>
          </div>
        </div>
        <div class="groupText">
          <p v-html="$t('message.desMessageFromChairmanCeoP1')"></p>
          <p>{{ $t('message.desMessageFromChairmanCeoP2') }}</p>
          <p>{{ $t('message.desMessageFromChairmanCeoP3') }}</p>
          <p>{{ $t('message.desMessageFromChairmanCeoP4') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.scrollToTop();
  }
};
</script>

<style scoped>
.boarding {
  padding-top: 120px;
  padding-bottom: 50px;
}

/* .fixed-bottom{
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
       
    } */
.groupTitle p {
  margin: 0px;
}

.groupTitle h2 {
  margin: 0px;
}

.row {
  padding-top: 25px;
}

.groupImg {
  height: 260px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: burlywood;
}

.groupImg img {
  height: 100%;
  object-fit: "contain";
}

.text-des p {
  font-size: small;
}

.text-des span {
  font-weight: 600;
}

.groupText {
  padding-top: 30px;
}

@media (min-width: 1270px) and (max-width: 2060px) {}

@media (width < 450px) {
  .text-des p {
    font-size: 14px;
  }

  .groupTitle {
    margin-top: -12px;
  }

  .groupText {
    padding-top: 10px;
  }

  .groupText p {
    font-size: small;
  }
}
</style>